import env from "../env";
import qs from "qs";
import axios from "axios";
import ky from "ky";
import _ from "lodash";

export default class ScanpixApi {
  constructor() {
    this.action = "";
    this.query = {};
    this.api = ky;

    this._addAxiosResponseInterceptor();
  }

  static baseURL() {
    if (env === "development") {
      return "http://localhost:8080/";
    }

    return "/";
  }

  _setAction(action = "") {
    this.action = action;
  }

  _setQuery(queryObject = {}) {
    this.query = queryObject;
  }

  _appendQuery(queryObject = {}) {
    this.query = {
      ...this.query,
      ...queryObject
    };
  }

  _removeQuery(queryNameToRemove = "") {
    this.query = _.omit(this.query, queryNameToRemove);
  }

  _getFetchUrl(options = {}) {
    let url = ScanpixApi.baseURL();
    if (this.action) url += this.action;
    if (Object.keys(this.query).length) url += `?${qs.stringify(this.query, options)}`;
    return url;
  }

  async _fetch(options = { ky: {}, qs: {} }) {
    options = { ...options };

    const returnFullResponse = !!options.returnFullResponse;
    const kyOptions = options.ky;

    let url = this._getFetchUrl(options?.qs);

    delete options.returnFullResponse;
    delete options.qs;

    let response;
    try {
      response = await this.api.get(url, {
        headers: { "X-SpAjaxRequest": true },
        credentials: "include",
        timeout: false,
        ...kyOptions
      });
    } catch (error) {
      throw error;
    }

    if (response.headers.get("x-spajaxexception") === "true") {
      throw new Error(`Got an exception from ${url}: ${JSON.stringify(await response.json())}`);
    }

    if (returnFullResponse) {
      return response;
    }

    let data;
    if (response.headers.get("content-type").match(/application\/json/g)) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    if (data && typeof data === "string") {
      if (data.trim() === '"OK_CONTINUE";') {
        // fixes bug in Safari where trailing semicolon is removed from response
        data = "OK_CONTINUE";
      }
    }

    return data;
  }

  async _post(postData = null, options = { ky: {}, qs: {} }) {
    options = { ...options };

    const returnFullResponse = !!options.returnFullResponse;
    const kyOptions = options.ky;

    let url = this._getFetchUrl(options?.qs);

    const isFormData = postData instanceof FormData;
    const dataOption = isFormData ? { body: postData } : { json: postData };

    let response;
    try {
      response = await this.api.post(url, {
        headers: { "X-SpAjaxRequest": true },
        credentials: "include",
        timeout: false,
        ...dataOption,
        ...kyOptions
      });
    } catch (error) {
      throw error;
    }

    if (returnFullResponse) {
      return response;
    }

    let data;
    if (response.headers.get("content-type").match(/application\/json/g)) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    if (data && typeof data === "string") {
      if (data.trim() === '"OK_CONTINUE";') {
        // fixes bug in Safari where trailing semicolon is removed from response
        data = "OK_CONTINUE";
      }
    }

    return data;
  }

  _changeLocation(options) {
    document.location.href = this._getFetchUrl(options);
  }

  _addAxiosResponseInterceptor() {
    this.api = this.api.extend({
      hooks: {
        afterResponse: [
          async (_request, options, response) => {
            if (response.type === "opaqueredirect") {
              if (options.redirectCallback) {
                options.redirectCallback();
                await new Promise(resolve =>
                  setTimeout(() => {
                    // Sets a timeout to give `options.redirectCallback()` room to complete
                    return resolve;
                  }, 5000)
                );
              }
            }

            if (response.status === 200) {
              // check if the API wants the user to authenticate
              if (
                response.headers.has("x-checklogin") &&
                response.headers.get("x-checklogin") === "true"
              ) {
                try {
                  // eslint-disable-next-line no-eval
                  const redirectUrl = eval(await response.text()).redirect;
                  window.location.replace(redirectUrl);

                  // Sets a timeout to give the redirect a chance to complete
                  return new Promise(resolve =>
                    setTimeout(() => {
                      return resolve;
                    }, 10000)
                  );
                } catch (e) {
                  // Don't do anything
                }
              }
            }

            response.options = options;

            return response;
          }
        ]
      }
    });

    axios.interceptors.response.use(
      response => {
        if (!response) {
          return response;
        }

        const shouldCheckLogin = response.headers["x-checklogin"];
        if (shouldCheckLogin && shouldCheckLogin === "true") {
          try {
            // eslint-disable-next-line no-eval
            const redirectUrl = eval(response.data).redirect;
            return window.location.replace(redirectUrl);
          } catch (e) {
            // Don't do anything
          }
        }

        return response;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }
}
