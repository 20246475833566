export const SET_IS_BUCKET_LOADING = "AGGREGATION_SET_IS_BUCKET_LOADING";
export const SET_LOADED_FILTER_GROUP = "AGGREGATION_SET_LOADED_FILTER_GROUP";
export const SET_LOADED_AGGREGATION_GROUP = "AGGREGATION_SET_LOADED_AGGREGATION_GROUP";
export const SET_EXPANDED_ITEMS = "AGGREGATION_SET_EXPANDED_ITEMS";
export const CLEAR_ALL_GROUPS = "AGGREGATION_CLEAR_ALL_GROUPS";
export const SET_EXPAND_CLICKED_GROUP_NAME = "AGGREGATION_SET_SET_EXPAND_CLICKED_GROUP_NAME";

export const setIsBucketLoading = loading => {
  return { type: SET_IS_BUCKET_LOADING, loading };
};

export const setLoadedFields = filterItem => {
  const filterType = filterItem.filterType;
  if (filterType === "filterGroup") {
    return {
      type: SET_LOADED_FILTER_GROUP,
      filterName: filterItem.name
    };
  } else if (filterType === "aggregations") {
    return {
      type: SET_LOADED_AGGREGATION_GROUP,
      filterName: filterItem.name
    };
  } else {
    return;
  }
};

export const setExpandedItems = filterName => {
  return {
    type: SET_EXPANDED_ITEMS,
    filterName
  };
};

export const clearAllGroups = () => ({
  type: CLEAR_ALL_GROUPS
});

export const setExpandClick = filterName => ({
  type: SET_EXPAND_CLICKED_GROUP_NAME,
  filterName
});
