import React, { useState, useRef, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link, matchPath, useLocation } from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";
import { useLastLocation } from "react-router-last-location";
import ky from "ky";
import { DateTime } from "luxon";
import { captureMessage, addBreadcrumb } from "@sentry/react";
import { indexRoute, previewRoute, albumIndexRoute } from "../../helpers/routes";
import { instanceIsInIframe } from "../../helpers/iframe";
import style from "../../assets/scss/components/header.module.scss";
import ShowOnScreenSize from "../helpers/show-on-screen-size";
import SearchField from "../search-field";
import IframeSynchronizeSearch from "../search/iframe-synchronize-search";
import InfoBanner from "./info-banner";
import MainMenu from "./main-menu";
import UserMenu from "./user-menu";
import { ReactComponent as Logo } from "../../assets/images/logos/logo.svg";

function Header({ authenticated }) {
  const [logoLink, setLogoLink] = useState(indexRoute);
  const [mainMenuData, setMainMenuData] = useState(null);

  const hamburgerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const pageMatch = (a, b = location.pathname) => {
    return matchPath(b, {
      path: a,
      exact: true
    });
  };

  const searchPageMatch = pageMatch(indexRoute);
  const previewPageMatch = pageMatch(previewRoute);

  const toggleMenu = (isOpen = !isMenuOpen) => {
    setIsMenuOpen(isOpen);
  };

  const isSearchPage = !!(searchPageMatch && searchPageMatch.isExact);
  const isPreviewPage = !!(previewPageMatch && previewPageMatch.isExact);

  const lastLocation = useLastLocation();

  const previousLocationWasSearchPage = pageMatch(indexRoute, lastLocation?.pathname)?.isExact;

  // update logo link when changing search and filters on the search page
  useEffect(() => {
    if (isSearchPage) {
      setLogoLink(location || indexRoute);
    }
  }, [location, isSearchPage]);

  // update logo link when moving away from the search page
  useEffect(() => {
    if (!isSearchPage && previousLocationWasSearchPage) {
      setLogoLink(lastLocation || indexRoute);
    }
  }, [isSearchPage, lastLocation, previousLocationWasSearchPage]);

  const [fetchingMainMenuDataFailed, setFetchingMainMenuDataFailed] = useState(false);
  // fetch the main menu items from ntb.no
  useEffect(() => {
    ky.get("https://www.ntb.no/api/v1/public/menu")
      .then(response => response.json())
      .then(data => setMainMenuData(data))
      .catch(error => {
        setFetchingMainMenuDataFailed(true);
        addBreadcrumb({ level: "error", message: error.toString() });
        captureMessage("Failed to get menu from ntb.no/api/v1/public/menus", "error");
      });
  }, []);

  const showMaintenanceBanner = useMemo(() => {
    const dateRangeEnvironmentVariable = process.env.REACT_APP_MAINTENANCE_BANNER_ACTIVE;
    const requireAuth = process.env.REACT_APP_MAINTENANCE_BANNER_REQUIRE_AUTH === "true";

    if (dateRangeEnvironmentVariable) {
      if (requireAuth && !authenticated) {
        return false;
      }

      try {
        const dateRange = dateRangeEnvironmentVariable.split(":");
        const today = DateTime.now().startOf("day");
        const startDate = DateTime.fromFormat(dateRange[0], "MM-dd-yyyy").startOf("day");
        const endDate = DateTime.fromFormat(dateRange[1], "MM-dd-yyyy").startOf("day");

        return today >= startDate && today <= endDate;
      } catch (e) {
        return false;
      }
    }

    return false;
  }, [authenticated]);

  return (
    <>
      {showMaintenanceBanner && (
        <InfoBanner
          heading={"Planlagt vedlikehold av NTBs bildeportal"}
          body={
            "Natt til søndag 11. desember kan bildeportalen i korte perioder være utilgjengelig eller ustabil. Vi beklager ulempen."
          }
          dismissibleKey={"11-12-22-MAINTENANCE"}
        />
      )}
      <header className={style.header}>
        {!instanceIsInIframe && !fetchingMainMenuDataFailed && (
          <div ref={hamburgerRef}>
            <HamburgerMenu
              className={style["header__hamburger-menu"]}
              isOpen={isMenuOpen}
              menuClicked={() => toggleMenu()}
              width={16}
              height={14}
              strokeWidth={1}
              rotate={360}
              borderRadius={0}
              animationDuration={0.6}
            />
          </div>
        )}
        <Link to={logoLink} className={style["header__logo"]}>
          <Logo />
        </Link>

        {(isSearchPage || isPreviewPage) && (
          <SearchField
            className={style["header__search-field"]}
            navigateToSearchPage={isPreviewPage}
          />
        )}
        {(isSearchPage || isPreviewPage) && instanceIsInIframe && <IframeSynchronizeSearch />}

        <div className={style["header__actions"]}>
          {authenticated && (
            <ShowOnScreenSize from={1200}>
              <Link
                to={albumIndexRoute}
                style={{ color: "#707070", whiteSpace: "nowrap", textDecoration: "none" }}
              >
                Mine album
              </Link>
            </ShowOnScreenSize>
          )}
          <div className={style["header__actions__user-menu"]}>
            <UserMenu />
          </div>
        </div>
      </header>

      {mainMenuData && (
        <MainMenu isOpen={isMenuOpen} menuItems={mainMenuData} toggleMenu={toggleMenu} />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(Header);
