import { SET_SELECTED_ASSETS, SET_HAS_MORE_SEARCH_RESULTS } from "../../actions/pages/search";

const searchPageReducer = (
  state = {
    selectedAssets: [],
    hasMoreSearchResults: false
  },
  action
) => {
  switch (action.type) {
    case SET_SELECTED_ASSETS: {
      return {
        ...state,
        selectedAssets: action.value
      };
    }

    case SET_HAS_MORE_SEARCH_RESULTS: {
      return {
        ...state,
        hasMoreSearchResults: action.value
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default searchPageReducer;
