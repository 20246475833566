import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Tooltip from "@tippyjs/react";
import { generate } from "shortid";
import classNames from "classnames";
import SearchSuggestionAction from "../helpers/api/search-suggestion";
import { indexRoute } from "../helpers/routes";
import iframeEventBus from "../helpers/iframe-event-bus";
import luceneValidator from "../helpers/lucene-validator";
import { withQuery } from "../helpers/path";
import style from "../assets/scss/components/search-field.module.scss";
import { fetchAssets } from "../store/actions/asset-search";
import { setSearchString, setTempSearchString, setMainGroup } from "../store/actions/search-filter";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import { ReactComponent as ClearIcon } from "../assets/icons/clear-alt.svg";
import { ReactComponent as WarningIcon } from "../assets/icons/warning.svg";
import { setIsAssetPreviewOpen } from "../store/actions/gallery";

function SearchField({
  className,
  navigateToSearchPage,
  fetchAssets,
  searchString,
  setSearchString,
  tempSearchString,
  setTempSearchString,
  mainGroup,
  setMainGroup,
  possibleMainGroups,
  synchronizeSplitScreenSearch,
  setIsAssetPreviewOpen
}) {
  const history = useHistory();
  const instanceId = generate();

  const searchInput = useRef(null);

  const [searchFieldFocused, setSearchFieldFocused] = useState(false);
  const [searchSuggestionsFocused, setSearchSuggestionsFocused] = useState(false);
  const [searchInputErrorMsg, setSearchInputErrorMsg] = useState(null);

  const mediaTypeOption = useMemo(
    () => [
      { value: "image", label: "Bilder" },
      { value: "video", label: "Video" },
      { value: "graphic", label: "Grafikk" }
    ],
    []
  );

  const getSelectedMediaTypeOption = useCallback(() => {
    const mediaType =
      possibleMainGroups.find(possibleMainGroup => possibleMainGroup.mainGroup === mainGroup)
        ?.type || "image";
    return mediaTypeOption.find(option => option.value === mediaType) || mediaTypeOption[0];
  }, [mediaTypeOption, mainGroup, possibleMainGroups]);

  let selectedMediaTypeOption = getSelectedMediaTypeOption();

  useEffect(() => {
    setTempSearchString(searchString);
  }, [searchString, setTempSearchString]);

  // Search suggestions
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  useEffect(() => {
    if (tempSearchString.length > 1 && searchInput.current === document.activeElement) {
      new SearchSuggestionAction()
        .getSearchSuggestions(tempSearchString, mainGroup)
        .then(data => {
          setSearchSuggestions(data);

          setSelectedSuggestionIndex(currentIndex => {
            // check if the current tempSearchString yields any search suggestions
            if (data.length === 0) {
              return null;
            }

            // check if the current search suggestions index is higher then the available
            // search suggestions
            if (data.length < currentIndex + 1) {
              return data.length - 1;
            }

            return currentIndex;
          });
        })
        .catch(() => {
          // catch
        });
    }
    setSearchInputErrorMsg(luceneValidator.validate(tempSearchString));
  }, [tempSearchString, mainGroup]);

  const suggestionMode = useMemo(
    () =>
      (searchFieldFocused || searchSuggestionsFocused) &&
      tempSearchString !== searchString &&
      searchSuggestions.length > 0,
    [
      searchFieldFocused,
      searchSuggestionsFocused,
      tempSearchString,
      searchString,
      searchSuggestions
    ]
  );

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(null);

  const navigateSuggestions = event => {
    const { key, target } = event;

    if (suggestionMode) {
      if (key === "ArrowDown") {
        if (selectedSuggestionIndex === null) {
          return setSelectedSuggestionIndex(0);
        }

        if (selectedSuggestionIndex + 1 < searchSuggestions.length) {
          return setSelectedSuggestionIndex(selectedSuggestionIndex + 1);
        }
      }

      if (key === "ArrowUp") {
        if (selectedSuggestionIndex === null) {
          return setSelectedSuggestionIndex(searchSuggestions.length - 1);
        }
        if (selectedSuggestionIndex !== 0) {
          return setSelectedSuggestionIndex(selectedSuggestionIndex - 1);
        }
      }
    }

    if (key === "Enter") {
      setIsAssetPreviewOpen(false);
      if (selectedSuggestionIndex !== null) {
        setNewSearchString(searchSuggestions[selectedSuggestionIndex].value);
      } else if (!searchInputErrorMsg) {
        setNewSearchString(target.value, true);
      }

      return setSelectedSuggestionIndex(null);
    }
  };

  const setNewSearchString = (value, force = false) => {
    if (searchString === value && force) {
      // force search update if user presses enter in the search field with the same search-string
      fetchAssets(force);
    }

    setSearchString(value);

    if (navigateToSearchPage) {
      // only navigate if enabled by the prop
      history.push(withQuery(indexRoute, { search: value, mainGroup }));
    }

    if (synchronizeSplitScreenSearch) {
      iframeEventBus.emit("imagePortal:iframe:searchStringChanged", value);
    }
  };

  const setNewMainGroupByMediaType = selectedMediaType => {
    const mainGroupObject = possibleMainGroups.find(
      possibleMainGroup => possibleMainGroup.type === selectedMediaType
    );
    const newMainGroup = mainGroupObject.mainGroup;
    setMainGroup(newMainGroup);
  };

  // consume the new search string from the DAM iframe and set it in the store
  useEffect(() => {
    iframeEventBus.on("dam:iframe:searchStringChanged", searchString => {
      history.push(withQuery(indexRoute, { search: searchString }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(style["search"], className)}>
      <div className={style["search__input-container"]}>
        <input
          className={classNames(style["search__input"], {
            [style["search__input__warning"]]: searchInputErrorMsg != null
          })}
          type="text"
          ref={searchInput}
          value={tempSearchString}
          placeholder="Hva leter du etter?"
          onChange={e => setTempSearchString(e.target.value)}
          onFocus={() => setSearchFieldFocused(true)}
          onBlur={() => setSearchFieldFocused(false)}
          // onBlur={e => setNewSearchString(e.target.value)}
          // onKeyPress={e => {
          //   if (e.key === "Enter") setNewSearchString(e.target.value, true);
          // }}
          onKeyDown={e => navigateSuggestions(e)}
        />
        {tempSearchString !== "" ? (
          <>
            {searchInputErrorMsg && (
              <Tooltip
                content={searchInputErrorMsg}
                animation="shift-away-subtle"
                placement="bottom"
              >
                <WarningIcon
                  className={classNames(style["search__icon"], style["search__icon__warning"])}
                />
              </Tooltip>
            )}
            <ClearIcon
              className={style["search__clear-input"]}
              onClick={() => {
                setTempSearchString("");
                setNewSearchString("");
              }}
            />
          </>
        ) : (
          <SearchIcon className={style["search__icon"]} />
        )}

        {suggestionMode && (
          <div
            className={style["search__input-container__suggestions"]}
            onMouseEnter={() => setSearchSuggestionsFocused(true)}
            onMouseLeave={() => setSearchSuggestionsFocused(false)}
          >
            {searchSuggestions.map((searchSuggestion, index) => (
              <div
                key={searchSuggestion.value}
                className={classNames(style["search__input-container__suggestions__item"], {
                  [style["search__input-container__suggestions__item--selected"]]:
                    selectedSuggestionIndex === index
                })}
                onClick={() => {
                  setSearchString(searchSuggestion.value);
                  searchInput.current.focus();
                  setSearchSuggestionsFocused(false);
                }}
              >
                {searchSuggestion.value}
              </div>
            ))}
          </div>
        )}
      </div>

      <Select
        instanceId={instanceId}
        className={style["search__select"]}
        options={mediaTypeOption}
        value={selectedMediaTypeOption}
        classNamePrefix={"search__select"}
        onChange={e => setNewMainGroupByMediaType(e.value)}
      />
    </div>
  );
}

SearchField.propTypes = {
  navigateToSearchPage: PropTypes.bool
};

const mapStateToProps = state => ({
  searchString: state.searchFilter.searchString,
  tempSearchString: state.searchFilter.tempSearchString,
  mainGroup: state.searchFilter.mainGroup,
  possibleMainGroups: state.searchFilter.possibleMainGroups,
  synchronizeSplitScreenSearch: state.splitScreen.synchronizeSearch
});

const mapDispatchToProps = {
  fetchAssets,
  setSearchString,
  setTempSearchString,
  setMainGroup,
  setIsAssetPreviewOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
