import { SPLIT_SCREEN_SYNCHRONIZE_SEARCH } from "../actions/split_screen";

const splitScreenReducer = (
  state = {
    synchronizeSearch: true
  },
  action
) => {
  switch (action.type) {
    case SPLIT_SCREEN_SYNCHRONIZE_SEARCH: {
      return {
        ...state,
        synchronizeSearch: action.value
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default splitScreenReducer;
