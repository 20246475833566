function env() {
  const hostname = window.location.hostname;
  if ("localhost" === hostname) {
    return "development";
  } else if ("bilder.ntb.no" === hostname) {
    return "production";
  } else if (hostname.endsWith("test.ntb.dev")) {
    return "aws-test";
  } else if (hostname.endsWith("stage.ntb.dev")) {
    return "aws-stage";
  } else if (hostname.endsWith("prod.ntb.dev")) {
    return "aws-prod";
  }
  else {
    return "development";
  }
}

export default env();
