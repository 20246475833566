import React, { useState, useRef, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { settingsRoute, albumIndexRoute } from "../../helpers/routes";
import { instanceIsInIframe } from "../../helpers/iframe";
import LoginAction from "../../helpers/api/login-action";
import UserExternalLink from "../helpers/user-external-link";
import style from "../../assets/scss/components/user-menu.module.scss";
import ShowOnScreenSize from "../helpers/show-on-screen-size";
import Modal from "../ui-element/modal";
import TextInput from "../form/text-input";
import Button from "../form/button";
import ImporsonationAction from "../../helpers/api/user-impersonation-action";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { LS_KEY_SEARCH_FILTER_SUBSCRIPTION } from "../../constants/localStorage";

function UserMenu({ authenticated, user }) {
  const wrapperRef = useRef(null);
  const dropdownRef = useRef(null);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
  const [showImpersonateModal, setShowImpersonateModal] = useState(false);
  const [impersonateUserName, setImpersonateUserName] = useState("");
  const [impersonationProgress, setImpersonationProgress] = useState(false);
  const [impersonationErrors, setImpersonationErrors] = useState("");

  useEffect(() => {
    // Close dropdown if clicked outside of element
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setUserMenuIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleDropdownClick = event => {
    if (dropdownRef.current) {
      for (const child of dropdownRef.current.children) {
        if (child.nodeName === "A" && child.contains(event.target)) {
          setUserMenuIsOpen(false);
          return;
        }
      }
    }
  };

  const triggerClicked = () => {
    if (!authenticated) {
      return login();
    }

    setUserMenuIsOpen(!userMenuIsOpen);
  };

  const logout = () => {
    // remove some of the data stored in localStorage
    window.localStorage.removeItem(LS_KEY_SEARCH_FILTER_SUBSCRIPTION);

    // log out
    new LoginAction().logout();
  };

  const login = () => {
    new LoginAction().login();
  };

  const openImpersonateForm = () => {
    setUserMenuIsOpen(!userMenuIsOpen);
    setShowImpersonateModal(true);
    setImpersonateUserName("");
  };

  const impersonate = async () => {
    if (!impersonateUserName) {
      return;
    }

    setImpersonationProgress(true);
    new ImporsonationAction().login(impersonateUserName).then(res => {
      if (res.error) {
        setImpersonationErrors(res.error);
        setTimeout(() => {
          document.location.reload();
        }, 2000);
      } else {
        document.location.reload();
      }
      setImpersonationProgress(false);
    });
  };

  const computedDisplayName = useMemo(() => {
    if (!user) {
      return null;
    }
    const { firstname, lastname, username } = user;
    if (!firstname && !lastname) {
      return username;
    }

    return `${user.firstname || ""} ${user.lastname || ""}`;
  }, [user]);

  return (
    <>
      <div ref={wrapperRef} className={style["user-menu"]}>
        <div className={style["user-menu__trigger"]} onClick={triggerClicked}>
          {authenticated ? (
            <UserIcon className={style["user-menu__trigger__user-icon"]} />
          ) : (
            <LoginIcon className={style["user-menu__trigger__login-icon"]} />
          )}

          <div className={style["user-menu__trigger__name"]}>
            {authenticated ? (instanceIsInIframe ? "" : computedDisplayName) : "Logg inn"}
          </div>
        </div>

        {userMenuIsOpen && (
          <div
            ref={dropdownRef}
            className={style["user-menu__dropdown"]}
            onClick={handleDropdownClick}
          >
            <ShowOnScreenSize to={1199}>
              <Link to={albumIndexRoute} className={classNames(style["user-menu__dropdown__item"])}>
                Mine album
              </Link>
              <div className={style["user-menu__dropdown__divider"]} />
            </ShowOnScreenSize>
            {(user.ntbInfoUser ||
              user.mediaManagerUser ||
              user.mediaBankUser ||
              user.newsServiceUser) && (
              <>
                <div className={style["user-menu__dropdown__text"]}>DINE APPLIKASJONER</div>
                <UserExternalLink
                  url={"https://kommunikasjon.ntb.no/"}
                  label={"Info"}
                  visible={user.ntbInfoUser}
                />
                <UserExternalLink
                  url={"https://client.sdl.no"}
                  label={"Media Manager"}
                  visible={user.mediaManagerUser}
                />
                <UserExternalLink
                  url={"https://mediebank.ntb.no/"}
                  label={"Mediebank"}
                  visible={user.mediaBankUser}
                />
                <UserExternalLink
                  url={"https://nyheter.ntb.no"}
                  label={"Nyhetstjenesten"}
                  visible={user.newsServiceUser}
                />
                <div className={style["user-menu__dropdown__divider"]} />
              </>
            )}
            <Link to={settingsRoute} className={style["user-menu__dropdown__item"]}>
              Innstillinger
            </Link>
            {user.roles.includes("admin") && (
              <a
                href="/statistics.action?adminWelcome"
                className={style["user-menu__dropdown__item"]}
              >
                Admin
              </a>
            )}
            {(user.roles.includes("superadmin") || user.roles.includes("admin")) && (
              <div className={style["user-menu__dropdown__item"]} onClick={openImpersonateForm}>
                Midlertidig logg inn
              </div>
            )}
            {(user.roles.includes("superadmin") || user.roles.includes("admin")) && (
              <div className={style["user-menu__dropdown__divider"]} />
            )}
            <div className={style["user-menu__dropdown__item"]} onClick={logout}>
              Logg ut
            </div>
          </div>
        )}
      </div>
      <Modal open={showImpersonateModal} onClose={() => setShowImpersonateModal(false)}>
        <header>Logg midlertidig inn som</header>
        <TextInput
          label="Brukernavn"
          onValueChange={e => setImpersonateUserName(e)}
          value={impersonateUserName}
        />
        {impersonationErrors ? <div style={{ color: "#FF0000" }}>{impersonationErrors}</div> : null}
        <footer>
          <Button onClick={() => setShowImpersonateModal(false)}>Avbryt</Button>
          <Button primary loading={impersonationProgress} onClick={impersonate}>
            Logg inn
          </Button>
        </footer>
      </Modal>
    </>
  );
}

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
  user: state.user.profile
});

export default connect(mapStateToProps)(UserMenu);
