import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import { setUserProfile } from "../store/actions/user";
import { useNewsPortalPreview } from "../helpers/news-portal-preview";
import MyPageAction from "../helpers/api/my-page-action";
import iframeEventBus from "../helpers/iframe-event-bus";
import { instanceIsInIframe } from "../helpers/iframe";
import Footer from "../components/layout/footer";
import Header from "../components/layout/header";
import style from "../assets/scss/layouts/default.module.scss";
import { ReactComponent as CloseIcon } from "../assets/icons/times.svg";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import SearchViewConfigAction from "../helpers/api/search-view-config-action";
import { setSearchViewConfig } from "../store/actions/asset-search";

function DefaultLayout({ setUserProfile, setSearchViewConfig, children, searchFilterMenuIsOpen }) {
  const GA = useGoogleAnalytics();
  const authChecking = useRef(false);

  const isNewsPortalPreview = useNewsPortalPreview();

  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    async function fetchViewConfig() {
      try {
        const data = await new SearchViewConfigAction().getSearchViewConfig();
        setSearchViewConfig(data);
      } catch (err) {}
    }
    fetchViewConfig();
  }, [setSearchViewConfig]);

  if (!authChecked) {
    if (!authChecking.current) {
      authChecking.current = true;

      new MyPageAction()
        .getProfile()
        .then(res => {
          authChecking.current = false;
          setAuthChecked(true);

          // We need to explicitly check for `false` as `res.authenticated` is falsy when authenticated as well
          setUserProfile(res.authenticated === false ? null : res);

          if (res.id) {
            Sentry.setUser({
              id: res.id,
              email: res.email || null,
              username: res.username || null
            });
          }

          GA.context.set("company_name", res.companyName);
        })
        .catch(() => {
          // silently swallow the error
          setAuthChecked(true);
        });
    }

    return null;
  }

  const sendCloseSplitScreenEvent = () => {
    iframeEventBus.emit("imageportal:native:closeSplitScreen");
  };

  return (
    <>
      {!isNewsPortalPreview && instanceIsInIframe && (
        <div className={style["iframe-close-button"]} onClick={() => sendCloseSplitScreenEvent()}>
          <CloseIcon />
        </div>
      )}

      {!isNewsPortalPreview && <Header />}
      {children}
      {!isNewsPortalPreview && !searchFilterMenuIsOpen && <Footer />}
    </>
  );
}

const mapStateToProps = state => ({ searchFilterMenuIsOpen: state.searchFilter.menuOpen });

const mapDispatchToProps = {
  setUserProfile,
  setSearchViewConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
