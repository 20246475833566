import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "../../assets/scss/components/user-menu.module.scss";
import NtbLogo from "../../assets/images/logos/NTB.ico";
import { ReactComponent as LaunchIcon } from "../../assets/icons/launch.svg";
import { ReactComponent as SwapIcon } from "../../assets/icons/swap.svg";

function UserExternalLink({ url, label, visible }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {visible && (
        <a
          href={url}
          className={style["user-menu__dropdown__item"]}
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ display: "flow-root" }}
        >
          <img src={NtbLogo} alt={"logo"} />
          <span>{label}</span>
          <span style={{ float: "right" }}>{!isHovered ? <LaunchIcon /> : <SwapIcon />}</span>
        </a>
      )}
    </>
  );
}

UserExternalLink.propTypes = {
  url: PropTypes.string.isRequired
};

export default UserExternalLink;
