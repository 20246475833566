import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import iframeEventBus from "../../helpers/iframe-event-bus";

function Theme({ children, preferredTheme }) {
  const currentTheme = useRef(null);

  const setCurrentTheme = theme => {
    const newTheme = `${theme}-theme`;

    // Trying to set the same theme - skip
    if (newTheme === currentTheme.current) return;

    document.body.classList.add(newTheme);
    document.body.classList.remove(currentTheme.current);

    currentTheme.current = newTheme;
  };

  // The `dam:iframe:setPreferredTheme` event is only supported for legacy use.
  // Use `setPreferredTheme` instead.
  iframeEventBus.on("dam:iframe:setPreferredTheme", preferredTheme => {
    const newTheme = preferredTheme.toLowerCase();
    setCurrentTheme(newTheme);
  });

  iframeEventBus.on("setPreferredTheme", preferredTheme => {
    const newTheme = preferredTheme.toLowerCase();
    setCurrentTheme(newTheme);
  });

  useEffect(() => {
    setCurrentTheme(preferredTheme);
  }, [preferredTheme]);

  return children || null;
}

const mapStateToProps = state => ({
  preferredTheme: state.settings.preferredTheme
});

export default connect(mapStateToProps)(Theme);
