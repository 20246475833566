import ScanpixApi from "./index";

export default class SearchViewConfigAction extends ScanpixApi {
  constructor() {
    super();
    this._setAction("search.action");
  }

  async getSearchViewConfig() {
    super._setQuery({ getSearchViewConfigAjax: "" });
    return super._fetch();
  }
}
