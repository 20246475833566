/**
 *
 * @param fn - React.lazy()
 * @param retriesLeft
 * @param interval
 * @return {Promise<unknown>}
 */
export function retryLazy(fn, retriesLeft = 3, interval = 1500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            window.location.reload();
            return;
          }

          // Passing on "reject" is the important part
          retryLazy(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
