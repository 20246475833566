import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import style from "../../assets/scss/components/form/button.module.scss";

function Button({
  loading,
  primary,
  primaryInverted,
  secondary,
  block,
  icon,
  disabled,
  children,
  className,
  ...props
}) {
  const [loadingState, setLoadingState] = useState(loading || false);
  const [loadingStep, setLoadingStep] = useState(113);
  const loadingInterval = useRef(0);

  const enableLoading = () => {
    loadingInterval.current = setInterval(() => {
      setLoadingStep(currentLoadingStep => {
        const newLoadingStep = Math.round(currentLoadingStep - (15 * currentLoadingStep) / 100);

        if (newLoadingStep === currentLoadingStep) {
          clearInterval(loadingInterval.current);
        }

        return newLoadingStep;
      });
    }, 300);

    setLoadingState(true);
  };

  const disableLoading = () => {
    clearInterval(loadingInterval.current);

    setLoadingStep(0);

    setTimeout(() => {
      setLoadingState(false);
      setLoadingStep(113);
    }, 500);
  };

  useEffect(() => {
    if (loading) {
      enableLoading();
    } else {
      disableLoading();
    }

    return () => clearInterval(loadingInterval.current);
  }, [loading]);

  return (
    <button
      className={classNames(
        style["button"],
        {
          [style["button--primary"]]: primary,
          [style["button--primary-inverted"]]: primaryInverted,
          [style["button--secondary"]]: secondary,
          [style["button--block"]]: block,
          [style["button--disabled"]]: disabled,
          [style["button--loading"]]: loadingState
        },
        className
      )}
      {...props}
    >
      <div
        className={style["button__content"]}
        // style={{
        //   transform: `scale(${loadingState ? 0.95 : 1})`
        // }}
      >
        {icon}
        {children}
      </div>
      <div
        className={style["button__loading-indicator"]}
        style={{
          opacity: loadingState ? 1 : 0
        }}
      >
        <svg className={style["button__loading-indicator__svg"]} viewBox="22 22 44 44">
          <circle
            className={style["button__loading-indicator__svg__circle"]}
            cx="44"
            cy="44"
            r="18"
            fill="none"
            strokeWidth="4"
            stroke="currentColor"
            style={{
              strokeDashoffset: `${loadingStep}px`
            }}
          />
        </svg>
      </div>
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  primary: PropTypes.bool,
  primaryInverted: PropTypes.bool,
  block: PropTypes.bool,
  icon: PropTypes.element,
  disabled: PropTypes.bool
};

export default Button;
