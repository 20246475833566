import ScanpixApi from "./index";
import _ from "lodash";

export default class SearchAction extends ScanpixApi {
  constructor() {
    super();
    this._setAction("xsearch.action");
  }

  searchString(searchString = "") {
    if (searchString.length) {
      super._appendQuery({ "search.searchString": searchString });
    }
    return this;
  }

  mainGroup(group) {
    super._appendQuery({ "search.mainGroup": group });
    return this;
  }

  offset(amount = 0) {
    super._appendQuery({ "search.offset": amount });
    return this;
  }

  searchAfter(sortValues = []) {
    super._appendQuery({ "search.searchAfter": sortValues });
    return this;
  }

  limit(limit) {
    super._appendQuery({ "search.showNumResults": limit });
    return this;
  }

  giveAggregations(shouldGiveAggregations) {
    if (shouldGiveAggregations) {
      super._appendQuery({ "search.giveAggregations": "true" });
    } else {
      super._removeQuery("search.giveAggregations");
    }
    return this;
  }

  returnAllAggregation(shouldReturnAll) {
    super._appendQuery({ "search.returnAll": shouldReturnAll });
    return this;
  }

  aggregationGroup(aggregationGroup) {
    if (aggregationGroup && aggregationGroup.length > 0) {
      super._appendQuery({ "search.aggregationGroup": aggregationGroup });
    }
    return this;
  }

  filterGroup(filterGroup) {
    if (filterGroup && filterGroup.length > 0) {
      super._appendQuery({ "search.filterGroup": filterGroup });
    }
    return this;
  }

  listMatchingAggregations(name) {
    if (name && name.length) {
      super._appendQuery({ "search.listMatchingAggregations": name });
    }
    return this;
  }

  sortOrder(order) {
    if (order && order.length) {
      super._appendQuery({ "search.sortOrder": order });
    }
    return this;
  }

  photographer(name) {
    if (name && name.length) {
      super._appendQuery({ "search.photographer": name });
    }
    return this;
  }

  distributorName(name) {
    if (name && name.length) {
      super._appendQuery({ "search.aggregations.distributorName": name });
    }
    return this;
  }

  credit(name) {
    if (name && name.length) {
      super._appendQuery({ "search.credit": name });
    }
    return this;
  }

  subjectTerms(name) {
    if (name && name.length) {
      super._appendQuery({ "search.aggregations.subjectTerm": name });
    }
    return this;
  }

  keywords(name) {
    if (name && name.length) {
      super._appendQuery({ "search.keywords": name });
    }
    return this;
  }

  moreLikeThis(id) {
    super._appendQuery({ "search.moreLikeThis": id });
    return this;
  }

  editorialTopics(topics) {
    if (topics && topics.length) {
      super._appendQuery({ "search.filters.editorialTopic": topics });
    }
    return this;
  }

  // withinSubscription(isWithinSubscription = false) {
  //   if (isWithinSubscription) {
  //     super._appendQuery({ 'search.subscription': 'subscription' });
  //   }
  //   return this;
  // }

  subscription(subscriptionType) {
    // subscription is either 'subscription' or 'punchcard'
    super._appendQuery({ "search.subscription": subscriptionType });
    return this;
  }

  timeLimit(limit) {
    if (limit) {
      super._appendQuery({ "search.timeLimit": limit });
    }
    return this;
  }

  createdMin(limit) {
    if (limit) {
      super._appendQuery({ "search.created.min": limit });
    }
    return this;
  }

  createdMax(limit) {
    if (limit) {
      super._appendQuery({ "search.created.max": limit });
    }
    return this;
  }

  events(events) {
    if (events && events.length) {
      super._appendQuery({ "search.aggregations.eventName": events });
    }
    return this;
  }

  persons(persons) {
    if (persons && persons.length) {
      super._appendQuery({ "search.aggregations.persons": persons });
    }
    return this;
  }

  properNames(names) {
    if (names && names.length) {
      super._appendQuery({ "search.aggregations.properName": names });
    }
    return this;
  }

  places(names) {
    if (names && names.length) {
      super._appendQuery({ "search.aggregations.places": names });
    }
    return this;
  }

  city(name) {
    if (name && name.length) {
      super._appendQuery({ "search.city": name });
    }
    return this;
  }

  country(name) {
    if (name && name.length) {
      super._appendQuery({ "search.country": name });
    }
    return this;
  }

  pictureOrientation(orientation) {
    if (orientation && orientation.length) {
      super._appendQuery({ "search.pictureOrientation": orientation });
    }
    return this;
  }

  colors(colors) {
    if (colors && colors.length) {
      super._appendQuery({ "search.colors": colors });
    }
    return this;
  }

  stockColor(color) {
    if (color) {
      super._appendQuery({ "search.stockColor": color });
    }
    return this;
  }

  blackAndWhite(showOnlyBlackAndWhite) {
    if (showOnlyBlackAndWhite) {
      super._appendQuery({ "search.saturation.max": "1" });
    }
    return this;
  }

  clearEdge(showOnlyClearEdge) {
    if (showOnlyClearEdge) {
      super._appendQuery({ "search.clearEdge": "true" });
    }
    return this;
  }

  creativeTopics(names) {
    if (names && names.length) {
      super._appendQuery({ "search.filters.creativeTopic": names });
    }
    return this;
  }

  creativePeople(names) {
    if (names && names.length) {
      super._appendQuery({ "search.filters.creativePeople": names });
    }
    return this;
  }

  numberOfPeople(values) {
    if (values && values.length) {
      super._appendQuery({ "search.numberOfPeople": values });
    }
    return this;
  }

  stockImageType(type) {
    if (type && type.length) {
      super._appendQuery({ "search.imageTypes": type });
    }
    return this;
  }

  videoEdit(type) {
    if (type && type.length) {
      super._appendQuery({ "search.filters.videoEdit": type });
    }
    return this;
  }

  videoTopics(names) {
    if (names && names.length) {
      super._appendQuery({ "search.filters.videoTopic": names });
    }
    return this;
  }

  vgTopics(names) {
    if (names && names.length) {
      super._appendQuery({ "search.filters.vgTopic": names });
    }
    return this;
  }

  assignmentNumbers(assignmentNumbers) {
    if (assignmentNumbers && assignmentNumbers.length) {
      super._appendQuery({ "search.assignmentNumber": assignmentNumbers });
    }
    return this;
  }

  refPtrs(imageIds) {
    if (imageIds && imageIds.length) {
      super._appendQuery({ "search.refPtrs": imageIds });
    }
    return this;
  }

  useUrl(callback) {
    if (!_.isFunction(callback)) return this;

    let url = super._getFetchUrl();
    callback(url);
    return this;
  }

  async fetch(options) {
    return super._fetch(options);
  }
}
