import { uniq } from "lodash";
import {
  CLEAR_ALL_GROUPS,
  SET_EXPANDED_ITEMS,
  SET_IS_BUCKET_LOADING,
  SET_LOADED_AGGREGATION_GROUP,
  SET_LOADED_FILTER_GROUP,
  SET_EXPAND_CLICKED_GROUP_NAME
} from "../actions/aggregations";

const aggregationReducer = (
  state = {
    loadedFilterGroup: [],
    loadedAggregationGroup: [],
    expandedItems: [],
    isBucketLoading: false,
    expandClick: undefined
  },
  action
) => {
  switch (action.type) {
    case SET_LOADED_FILTER_GROUP:
      return { ...state, loadedFilterGroup: uniq([...state.loadedFilterGroup, action.filterName]) };

    case SET_LOADED_AGGREGATION_GROUP:
      return {
        ...state,
        loadedAggregationGroup: uniq([...state.loadedAggregationGroup, action.filterName])
      };

    case SET_IS_BUCKET_LOADING:
      return { ...state, isBucketLoading: action.loading };

    case CLEAR_ALL_GROUPS: {
      return {
        ...state,
        loadedFilterGroup: [],
        loadedAggregationGroup: [],
        expandedItems: [],
        isBucketLoading: false
      };
    }

    case SET_EXPANDED_ITEMS: {
      const expandedItems = state.expandedItems;
      const filterName = action.filterName;
      let newItems = [];

      if (expandedItems.includes(filterName)) {
        newItems = [...new Set(expandedItems.filter(n => n !== filterName))];
      } else {
        newItems = [...new Set([...expandedItems, filterName])];
      }
      return { ...state, expandedItems: newItems };
    }

    case SET_EXPAND_CLICKED_GROUP_NAME: {
      return { ...state, expandClick: action.filterName };
    }

    default:
      return state;
  }
};

export default aggregationReducer;
