//Action Types
export const SET_SELECTED_ASSETS = "SEARCH_PAGE_SET_SELECTED_ASSETS";
export const SET_HAS_MORE_SEARCH_RESULTS = "SEARCH_PAGE_SET_HAS_MORE_SEARCH_RESULTS";

//Action Creators
export const setSelectedAssets = value => ({
  type: SET_SELECTED_ASSETS,
  value
});

export const setHasMoreSearchResults = value => ({
  type: SET_HAS_MORE_SEARCH_RESULTS,
  value
});
