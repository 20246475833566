import { SET_USER_PROFILE } from "../actions/user";

const userReducer = (
  state = {
    authenticated: false,
    profile: null
  },
  action
) => {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return {
        ...state,
        authenticated: !!action.value,
        profile: action.value
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default userReducer;
