// Action types
export const TOOGLE_FILTER_MENU = "ALBUM_FILTER_TOOGLE_FILTER_MENU";
export const SET_SORT_ORDER = "ALBUM_FILTER_SET_SORT_ORDER";

export const toogleFilterMenu = () => ({ type: TOOGLE_FILTER_MENU });

export const setSortOrder = (sortField, sortOrder) => ({
  type: SET_SORT_ORDER,
  sortField,
  sortOrder
});
