import { concat } from "lodash";
import { LS_KEY_SEARCH_FILTER_SUBSCRIPTION } from "../../constants/localStorage";

import {
  SET_OFFSET,
  SET_SEARCH_AFTER,
  SET_SEARCH_STRING,
  SET_TEMP_SEARCH_STRING,
  SET_MAIN_GROUP,
  SET_SORT_ORDER,
  SET_PHOTOGRAPHER,
  SET_DISTRIBUTOR_NAME,
  SET_CREDIT,
  SET_SUBJECT_TERMS,
  SET_KEYWORDS,
  SET_EDITORIAL_TOPICS,
  SET_SUBSCRIPTION,
  SET_TIME_LIMIT,
  SET_CREATED_MIN,
  SET_CREATED_MAX,
  SET_EVENTS,
  SET_PERSONS,
  SET_PROPER_NAMES,
  SET_PLACES,
  SET_CITY,
  SET_COUNTRY,
  SET_PICTURE_ORIENTATION,
  SET_COLORS,
  SET_STOCK_COLOR,
  SET_BLACK_AND_WHITE,
  SET_CLEAR_EDGE,
  SET_CREATIVE_TOPICS,
  SET_CREATIVE_PEOPLE,
  SET_NUMBER_OF_PEOPLE,
  SET_STOCK_IMAGE_TYPE,
  SET_VIDEO_EDIT,
  SET_VIDEO_TOPICS,
  SET_CUSTOMER_TOPICS,
  SET_ASSIGNMENT_NUMBERS,
  SET_VG_TOPICS,
  SET_REF_PTRS,
  CLEAR_CREATED_MIN_MAX,
  CLEAR_SEARCH_FILTER,
  TOGGLE_MENU
} from "../actions/search-filter";

const defaultFilters = {
  sortOrder: null,
  photographer: null,
  distributorName: [],
  credit: null,
  keywords: null,
  subjectTerms: [],
  editorialTopics: [],
  // subscription: null,
  timeLimit: null,
  createdMin: null,
  createdMax: null,
  events: [],
  persons: [],
  properNames: [],
  places: [],
  city: null,
  country: null,
  pictureOrientation: null,
  colors: [],
  stockColor: null,
  blackAndWhite: false,
  clearEdge: false,
  customerTopics: [],
  creativeTopics: [],
  creativePeople: [],
  numberOfPeople: [],
  stockImageType: null,
  videoEdit: [],
  videoTopics: [],
  assignmentNumbers: [],
  vgTopics: [],
  refPtrs: []
};

const searchFilterReducer = (
  state = {
    ...defaultFilters,
    defaultFilters,
    searchAfter: [],
    offset: 0,
    limit: 50,
    searchString: "",
    tempSearchString: "",
    mainGroup: "editorial",
    possibleMainGroups: [
      {
        label: "Redaksjonelle",
        description: "Nyhets- og arkivbilder fra hele verden",
        mainGroup: "editorial",
        type: "image",
        alsoCheck: true,
        alsoCheckLabel: "Redaksjonelle bilder",
        requiresAuthentication: true
      },
      {
        label: "Nordisk Premium",
        description: "Illustrasjonsbilder sett med nordiske øyne",
        mainGroup: "creative",
        type: "image",
        alsoCheck: true,
        alsoCheckLabel: "Nordisk Premium"
      },
      {
        label: "Stockbilder",
        description: "Illustrasjonsbilder fra hele verden",
        mainGroup: "microstock",
        type: "image"
      },
      { label: "Panther", mainGroup: "panther", type: "image" },
      { label: "Intern bildebank", mainGroup: "customer", type: "image" },
      {
        label: "Redaksjonelle",
        description: "Nyhets- og arkiv videoer fra hele verden",
        mainGroup: "video",
        type: "video",
        alsoCheck: true,
        alsoCheckLabel: "Redaksjonelle videoer",
        requiresAuthentication: true
      },
      {
        label: "Stockvideo",
        description: "Illustrasjonsvideoer fra hele verden",
        mainGroup: "microstockvideo",
        type: "video"
      },
      {
        label: "APs historiske arkiv",
        description: "Lenke til søk i APs historiske videoarkiv (krever registrering)",
        url: "https://www.aparchive.com/register",
        external: true,
        type: "video"
      },
      {
        label: "Livevideodekning",
        description: "Oversikt over NTBs livesendinger (krever videoabonnement)",
        url: "https://livevideo.ntb.no",
        external: true,
        type: "video"
      },
      {
        label: "Nyhetsgrafikk",
        mainGroup: "graphic",
        type: "graphic",
        alsoCheck: true,
        alsoCheckLabel: "Nyhetsgrafikk"
      }
    ],
    distributorName: [],
    subscription: (() => {
      let dataFromLocalStorage = window.localStorage.getItem(LS_KEY_SEARCH_FILTER_SUBSCRIPTION);
      if (dataFromLocalStorage === "null") {
        // We do want to store "null" in the localStorage because we need to know if the user has a
        // set preference of not filtering on subscription - if we find a string we'll serialize it
        // as `null`
        dataFromLocalStorage = null;
      }
      return dataFromLocalStorage || null;
    })(),
    menuOpen: false
  },
  action
) => {
  switch (action.type) {
    case SET_OFFSET: {
      return {
        ...state,
        offset: action.value,
        searchAfter: []
      };
    }

    case SET_SEARCH_AFTER: {
      return {
        ...state,
        searchAfter: action.value,
        offset: 0
      };
    }

    case SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_TEMP_SEARCH_STRING: {
      return {
        ...state,
        tempSearchString: action.value
      };
    }

    case SET_MAIN_GROUP: {
      return {
        ...state,
        mainGroup: action.value,
        searchAfter: [],
        offset: 0,
        ...JSON.parse(JSON.stringify(state.defaultFilters))
      };
    }

    case SET_SORT_ORDER: {
      return {
        ...state,
        sortOrder: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_PHOTOGRAPHER: {
      return {
        ...state,
        photographer: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_DISTRIBUTOR_NAME: {
      return {
        ...state,
        distributorName: Array.isArray(action.value)
          ? [...action.value]
          : action.value == null
          ? []
          : [action.value],
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CREDIT: {
      return {
        ...state,
        credit: action.value,

        searchAfter: [],
        offset: 0
      };
    }

    case SET_EDITORIAL_TOPICS: {
      return {
        ...state,
        editorialTopics: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CUSTOMER_TOPICS: {
      return {
        ...state,
        customerTopics: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_ASSIGNMENT_NUMBERS: {
      return {
        ...state,
        assignmentNumbers: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_REF_PTRS: {
      return {
        ...state,
        refPtrs: Array.isArray(action.value)
          ? [...action.value]
          : action.value == null
          ? []
          : [action.value],
        searchAfter: [],
        offset: 0
      };
    }

    case SET_SUBJECT_TERMS: {
      return {
        ...state,
        subjectTerms: Array.isArray(action.value)
          ? [...action.value]
          : action.value == null
          ? []
          : [action.value],
        searchAfter: [],
        offset: 0
      };
    }

    case SET_KEYWORDS: {
      return {
        ...state,
        keywords: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_SUBSCRIPTION: {
      let storeValue = action.value;
      if (storeValue === null) {
        // We do want to store "null" in the localStorage because we need to know if the user has a
        // set preference of not filtering on subscription
        storeValue = "null";
      }
      window.localStorage.setItem(LS_KEY_SEARCH_FILTER_SUBSCRIPTION, storeValue);

      return {
        ...state,
        subscription: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_TIME_LIMIT: {
      return {
        ...state,
        timeLimit: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CREATED_MIN: {
      return {
        ...state,
        createdMin: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CREATED_MAX: {
      return {
        ...state,
        createdMax: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case CLEAR_CREATED_MIN_MAX: {
      return {
        ...state,
        createdMin: null,
        createdMax: null,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_EVENTS: {
      return {
        ...state,
        events: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_PERSONS: {
      return {
        ...state,
        persons: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_PROPER_NAMES: {
      return {
        ...state,
        properNames: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_PLACES: {
      return {
        ...state,
        places: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CITY: {
      return {
        ...state,
        city: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_COUNTRY: {
      return {
        ...state,
        country: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_PICTURE_ORIENTATION: {
      return {
        ...state,
        pictureOrientation: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_COLORS: {
      return {
        ...state,
        colors: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_STOCK_COLOR: {
      return {
        ...state,
        stockColor: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_BLACK_AND_WHITE: {
      try {
        action.value = JSON.parse(action.value);
      } catch (e) {
        action.value = false;
      }

      return {
        ...state,
        blackAndWhite: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CLEAR_EDGE: {
      try {
        action.value = JSON.parse(action.value);
      } catch (e) {
        action.value = false;
      }

      return {
        ...state,
        clearEdge: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CREATIVE_TOPICS: {
      return {
        ...state,
        creativeTopics: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_CREATIVE_PEOPLE: {
      return {
        ...state,
        creativePeople: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_NUMBER_OF_PEOPLE: {
      return {
        ...state,
        numberOfPeople: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_STOCK_IMAGE_TYPE: {
      return {
        ...state,
        stockImageType: action.value,
        searchAfter: [],
        offset: 0
      };
    }

    case SET_VIDEO_EDIT: {
      return {
        ...state,
        videoEdit: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_VIDEO_TOPICS: {
      return {
        ...state,
        videoTopics: concat(action.value),
        searchAfter: [],
        offset: 0
      };
    }

    case SET_VG_TOPICS: {
      return {
        ...state,
        vgTopics: concat(action.value),

        searchAfter: [],
        offset: 0
      };
    }

    case CLEAR_SEARCH_FILTER: {
      window.localStorage.setItem(LS_KEY_SEARCH_FILTER_SUBSCRIPTION, "null");
      return {
        ...state,
        ...JSON.parse(JSON.stringify(state.defaultFilters)),
        searchAfter: [],
        offset: 0,
        subscription: null
      };
    }

    case TOGGLE_MENU: {
      return {
        ...state,
        menuOpen: !state.menuOpen
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};
export default searchFilterReducer;
