import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { indexRoute } from "../../helpers/routes";
import style from "../../assets/scss/components/footer.module.scss";
import { ReactComponent as Logo } from "../../assets/images/logos/logo.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedin.svg";

function Footer({ searchPageHasMoreSearchResults }) {
  const location = useLocation();

  const searchPageMatch = matchPath(location.pathname, {
    path: indexRoute,
    exact: true
  });

  const isSearchPage = !!(searchPageMatch && searchPageMatch.isExact);

  const [footerStyle, setFooterStyle] = useState({});

  useEffect(() => {
    if (isSearchPage && searchPageHasMoreSearchResults) {
      setFooterStyle({ display: "none", pointerEvents: "none" });
    } else {
      setFooterStyle({});
    }
  }, [isSearchPage, searchPageHasMoreSearchResults]);

  return (
    <footer className={style["footer"]} style={footerStyle}>
      <div className={style["footer__menu"]}>
        <div className={style["footer__menu__col"]}>
          <div className={style["footer__menu__col__container"]}>
            <Logo className={style["footer__menu__col__container__logo"]} />
            <div className={style["footer__menu__col__container__item"]}>
              <a href="https://ntb.no" target="_blank" rel="noopener noreferrer">
                Gå til NTB.no
              </a>
            </div>
            <div className={style["footer__menu__col__container__item"]}>
              <a
                href="https://www.ntb.no/personvernerklaering"
                target="_blank"
                rel="noopener noreferrer"
              >
                Personvern
              </a>
            </div>
            <div className={style["footer__menu__col__container__item"]}>
              <a
                href="https://www.ntb.no/bilder/bilderettigheter-og-avtalevilkar"
                target="_blank"
                rel="noopener noreferrer"
              >
                Standardvilkår
              </a>
            </div>
          </div>
        </div>
        <div className={style["footer__menu__col"]}>
          <div className={style["footer__menu__col__container"]}>
            <div className={style["footer__menu__col__container__header"]}>Kundesenter</div>
            <div className={style["footer__menu__col__container__item"]}>22 00 32 00</div>
            <div className={style["footer__menu__col__container__item"]}>kundesenter@ntb.no</div>
            <div className={style["footer__menu__col__container__item"]}>
              Man - fre: 8:30 - 16:00
            </div>
          </div>
        </div>
        <div className={style["footer__menu__col"]}>
          <div className={style["footer__menu__col__container"]}>
            <div className={style["footer__menu__col__container__header"]}>Fotodesken</div>
            <div className={style["footer__menu__col__container__item"]}>22 00 32 64</div>
            <div className={style["footer__menu__col__container__item"]}>ntb.fotodesk@ntb.no</div>
            <div className={style["footer__menu__col__container__item"]}>
              Mandag–fredag: 7.15–22.30
            </div>
            <div className={style["footer__menu__col__container__item"]}>
              Lør–søndag: 09.00–22.30
            </div>
          </div>
        </div>
        <div className={style["footer__menu__col"]}>
          <div className={style["footer__menu__col__container"]}>
            <div
              className={`${style["footer__menu__col__container__item"]} ${style["footer__menu__col__container__item--social"]}`}
            >
              <a href="https://www.facebook.com/ntbno/" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
                Facebook
              </a>
            </div>
            <div
              className={`${style["footer__menu__col__container__item"]} ${style["footer__menu__col__container__item--social"]}`}
            >
              <a
                href="https://www.instagram.com/ntb_scanpix/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
                Instagram
              </a>
            </div>
            <div
              className={`${style["footer__menu__col__container__item"]} ${style["footer__menu__col__container__item--social"]}`}
            >
              <a
                href="https://www.linkedin.com/company/ntb/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={style.footer__bottom}>
        Vi er landets største leverandør av visuelt innhold med et digitalt arkiv på over 300
        millioner bilder og videoer.
      </div>
    </footer>
  );
}

const mapStateToProps = state => ({
  searchPageHasMoreSearchResults: state.searchPage.hasMoreSearchResults
});

export default connect(mapStateToProps)(Footer);
