//Action Types
export const SPLIT_SCREEN_SYNCHRONIZE_SEARCH = "SPLIT_SCREEN_SYNCHRONIZE_SEARCH";

//Action Creators
export function setSynchronizeSplitScreenSearch(value = true) {
  return {
    type: SPLIT_SCREEN_SYNCHRONIZE_SEARCH,
    value
  };
}
