import ScanpixApi from "./index";
import { isObjectLike } from "lodash";

export default class SearchSuggestionAction extends ScanpixApi {
  constructor() {
    super();
    this._setAction("suggest.action");
  }

  async getSearchSuggestions(partialSearchString, mainGroup) {
    super._setQuery({
      json: "",
      type: "searchString",
      mainGroup,
      suggestSearch: partialSearchString
    });
    const response = await super._fetch();

    if (!isObjectLike(response)) {
      throw Error(`Response did not match expected search suggestions type - got: ${response}`);
    }

    return response;
  }
}
