import { useEffect } from "react";

import { DateTime } from "luxon";
import { hotjar } from "react-hotjar";

const Hotjar = () => {
  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ACTIVE) {
      try {
        const dateRange = process.env.REACT_APP_HOTJAR_ACTIVE.split(":");
        const today = DateTime.now().startOf("day");
        const startDate = DateTime.fromFormat(dateRange[0], "MM-dd-yyyy").startOf("day");
        const endDate = DateTime.fromFormat(dateRange[1], "MM-dd-yyyy").startOf("day");

        if (today >= startDate && today <= endDate) {
          hotjar.initialize(2588571, 6);
        }
      } catch (e) {
        // Silently swallow the error
      }
    }
  }, []);

  return null;
};

export default Hotjar;
