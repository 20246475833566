import {
  FETCHING_ASSETS,
  SET_LATEST_FETCH_ID,
  ASSETS_RECEIVED,
  SET_HUMAN_RESULT,
  SET_AGGREGATED_FILTERS,
  SET_SEARCH_INSTANCE,
  SET_SEARCH_VIEW_CONFIG,
  FETCHING_RESULTS_FROM_OTHER_BASES,
  RECEIVED_RESULTS_FROM_OTHER_BASES,
  SET_OTHER_MAIN_GROUPS_WITH_RESULTS
} from "../actions/asset-search";

const assetSearchReducer = (
  state = {
    instance: null,
    fetchId: null,
    assets: [],
    totalResults: 0,
    humanResults: {},
    loading: true,
    loadingResultsFromOtherBases: false,
    lastSearchUrl: "",
    aggregatedFilters: [],
    searchViewConfig: {},
    otherMainGroupsWithResults: []
  },
  action
) => {
  switch (action.type) {
    case SET_SEARCH_INSTANCE: {
      return {
        ...state,
        instance: action.value
      };
    }

    case FETCHING_ASSETS: {
      if (action.append || action.prepend) {
        return {
          ...state,
          lastSearchUrl: action.url,
          loading: true
        };
      }

      return {
        ...state,
        lastSearchUrl: action.url,
        assets: [],
        loading: true
        // humanResults: {}
      };
    }

    case SET_LATEST_FETCH_ID: {
      return {
        ...state,
        fetchId: action.value
      };
    }

    case FETCHING_RESULTS_FROM_OTHER_BASES: {
      return {
        ...state,
        loadingResultsFromOtherBases: true
      };
    }

    case RECEIVED_RESULTS_FROM_OTHER_BASES: {
      return {
        ...state,
        loadingResultsFromOtherBases: false
      };
    }

    case SET_OTHER_MAIN_GROUPS_WITH_RESULTS: {
      return {
        ...state,
        otherMainGroupsWithResults: action.value
      };
    }

    case ASSETS_RECEIVED: {
      if (action.prepend) {
        return {
          ...state,
          assets: [...action.value.images, ...state.assets],
          totalResults: action.value.numResults,
          loading: false
        };
      }

      if (action.append) {
        return {
          ...state,
          assets: [...state.assets, ...action.value.images],
          totalResults: action.value.numResults,
          loading: false
        };
      }

      return {
        ...state,
        assets: action.value.images,
        totalResults: action.value.numResults,
        loading: false
      };
    }

    case SET_HUMAN_RESULT: {
      return {
        ...state,
        humanResults: {
          ...state.humanResults,
          [action.mainGroup]: action.value
        }
      };
    }

    case SET_AGGREGATED_FILTERS: {
      return {
        ...state,
        aggregatedFilters: action.value
      };
    }

    case SET_SEARCH_VIEW_CONFIG: {
      return {
        ...state,
        searchViewConfig: action.value
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default assetSearchReducer;
