import LightboxAction from "../../helpers/api/lightbox-action";

export const ALBUM_PER_FETCH_SIZE = 30;
//Action Types
export const SET_VIEW_MODE = "ALBUM_SET_VIEW_MODE";
export const SET_ASSETS = "ALBUM_SET_ASSETS";
export const FETCHING_ALBUM_LIST = "ALBUM_FETCHING_ALBUM_LIST";
export const SET_ALBUM_LIST = "ALBUM_SET_ALBUM_LIST";
export const SET_ALBUM_DETAILS = "ALBUM_SET_SET_ALBUM_DETAILS";
export const FETCHING_ALBUM = "ALBUM_SET_FETCHING_ALBUM";
export const SET_ALBUM_MESSAGE = "ALBUM_SET_ALBUM_MESSAGE";
export const SET_ALBUM_SEARCH_QUERY = "ALBUM_SET_ALBUM_SEARCH_QUERY";
export const SET_ALBUM_OFFSET = "ALBUM_SET_ALBUM_OFFSET";
export const SET_HAS_MORE_ALBUMS = "ALBUM_SET_HAS_MORE_ALBUMS";
export const SET_ALBUM_SEARCH_TOTAL_COUNT = "ALBUM_SET_ALBUM_SEARCH_TOTAL_COUNT";

//Action Creators
export const fetchAlbumList = fetchFresh => {
  return (dispatch, getState) => {
    const album = getState().album;
    const albumFilter = getState().albumFilter;
    const user = getState().user;

    if (!user.authenticated) {
      return false;
    }

    if (!fetchFresh && album.albumListLoading) {
      return false;
    }

    // HasMore should be true when fetching a fresh list
    if (!!fetchFresh) {
      dispatch(setHasMore(true));
      dispatch(setAlbumOffset(0));
    }

    dispatch(fetchingAlbumList());
    new LightboxAction()
      .listLightboxes(
        album.albumQuery,
        albumFilter.sortField,
        albumFilter.sortOrder,
        !!fetchFresh ? 0 : album.offset
      )
      .then(response => {
        if (typeof response === "string") {
          dispatch(setAlbumMessage(response));
        } else {
          const { totalCount, albums } = response;
          dispatch(setTotalAlbumCount(totalCount));
          if (!!fetchFresh) {
            if (!response || totalCount <= albums.length) {
              dispatch(setHasMore(false));
            }
            dispatch(setAlbumList(albums));
          } else {
            const all = new Set([...album.albumList, ...albums]);
            if (!response || totalCount <= all.size) {
              dispatch(setHasMore(false));
            }
            dispatch(setAlbumList([...all]));
          }
        }
      });
  };
};

export const fetchAlbumById = lightboxId => {
  return dispatch => {
    dispatch(fetchingAlbum(true));
    new LightboxAction().fetchLightboxById(lightboxId).then(lightbox =>
      dispatch(setAlbumDetails(lightbox), err => {
        console.log("Error", err);
        dispatch(fetchingAlbum(false));
      })
    );
  };
};

export const setViewMode = value => ({
  type: SET_VIEW_MODE,
  value
});

export const setAssets = value => ({
  type: SET_ASSETS,
  value
});

export const fetchingAlbumList = () => ({
  type: FETCHING_ALBUM_LIST
});

export const fetchingAlbum = value => ({
  type: FETCHING_ALBUM,
  value
});

export const setAlbumQuery = value => ({
  type: SET_ALBUM_SEARCH_QUERY,
  value
});

export const setAlbumOffset = offset => {
  return { type: SET_ALBUM_OFFSET, offset };
};

export const setAlbumList = value => ({
  type: SET_ALBUM_LIST,
  value
});

const setTotalAlbumCount = count => ({ type: SET_ALBUM_SEARCH_TOTAL_COUNT, count });

const setAlbumMessage = value => ({ type: SET_ALBUM_MESSAGE, value });

const setAlbumDetails = value => ({ type: SET_ALBUM_DETAILS, value });

const setHasMore = hasMore => ({ type: SET_HAS_MORE_ALBUMS, hasMore });
