import qs from "qs";
import { useLocation } from "react-router-dom";

// Generates url path with params
export const withParams = (url, params) => {
  Object.keys(params).forEach(key => {
    url = url.replace(`:${key}`, params[key]);
  });

  return url;
};

// Generates url path with search query
export const withQuery = (url, queryObject) => {
  return `${url}?${qs.stringify(queryObject)}`;
};

// Returns search query from active page
export const useQuery = () => {
  const { search } = useLocation();
  // NOTE: The default array limit for qs is 20. If array size is more than 20 it returns an object instead of array.
  // But when we search shared album items using list of ids then this might overflow. So, we are increasing the limit here.
  // https://github.com/ljharb/qs#parsing-arrays
  return qs.parse(search, { ignoreQueryPrefix: true, arrayLimit: 1000 });
};
