import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import style from "../../assets/scss/components/layout/info-banner.module.scss";
import { ReactComponent as InformationIcon } from "../../assets/icons/information.svg";
import { ReactComponent as TimesIcon } from "../../assets/icons/times.svg";

const InfoBanner = ({ heading, body, dismissibleKey }) => {
  const localStorageKey = `INFO_BANNER_DISMISSED_${dismissibleKey}`;
  const [show, setShow] = useState(
    dismissibleKey ? !Boolean(window.localStorage.getItem(localStorageKey)) : true
  );

  const dismiss = useCallback(() => {
    window.localStorage.setItem(localStorageKey, "true");

    setShow(false);
  }, [localStorageKey]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{
            height: 0
          }}
          initial={{ height: 0 }}
          animate={{ height: "auto", transition: { delay: 0.8 } }}
          className={style["info-banner-container"]}
        >
          <div className={style["info-banner"]}>
            <InformationIcon className={style["info-banner__icon"]} />
            <div>
              <h1 className={style["info-banner__heading"]}>{heading}</h1>
              <p className={style["info-banner__body"]}>{body}</p>
            </div>
            {dismissibleKey && (
              <div onClick={dismiss} className={style["info-banner__dismiss"]}>
                <TimesIcon className={style["info-banner__dismiss__icon"]} />
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

InfoBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  dismissibleKey: PropTypes.string
};

export default InfoBanner;
