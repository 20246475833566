import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createReduxEnhancer } from "@sentry/react";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import env from "./../helpers/env";

import globalReducer from "./reducers/global";
import galleryReducer from "./reducers/gallery";
import albumReducer from "./reducers/album";
import assetSearchReducer from "./reducers/asset-search";
import searchFilterReducer from "./reducers/search-filter";
import settingsReducer from "./reducers/settings";
import userReducer from "./reducers/user";
import splitScreenReducer from "./reducers/split_screen";
import searchPageReducer from "./reducers/pages/search";
import albumFilterReducer from "./reducers/album-filter";
import aggregationReducer from "./reducers/aggregation";

// collect all reducers
const rootReducer = combineReducers({
  global: globalReducer,
  gallery: galleryReducer,
  album: albumReducer,
  albumFilter: albumFilterReducer,
  assetSearch: assetSearchReducer,
  searchFilter: searchFilterReducer,
  settings: settingsReducer,
  user: userReducer,
  splitScreen: splitScreenReducer,
  searchPage: searchPageReducer,
  aggregations: aggregationReducer
});

const isDevelopmentMode = env === "development";

// setup middleware
const middleware = [];
middleware.push(thunkMiddleware);

// setup enhancers
const enhancers = [];
enhancers.push(createReduxEnhancer());

if (process.env.ENABLE_LOGGER && isDevelopmentMode) {
  middleware.push(createLogger());
}

const applyMiddlewares = applyMiddleware(...middleware);
const composedEnhancers = isDevelopmentMode
  ? composeWithDevTools(applyMiddlewares, ...enhancers)
  : compose(applyMiddlewares, ...enhancers);

const store = createStore(rootReducer, composedEnhancers);

export default store;
