//Action Types
export const SET_OFFSET = "SET_OFFSET";
export const SET_SEARCH_AFTER = "SET_SEARCH_AFTER";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_TEMP_SEARCH_STRING = "SET_TEMP_SEARCH_STRING";
export const SET_MAIN_GROUP = "SET_MAIN_GROUP";
export const SET_SORT_ORDER = "SET_SORT_ORDER";
export const SET_PHOTOGRAPHER = "SET_PHOTOGRAPHER";
export const SET_DISTRIBUTOR_NAME = "SET_DISTRIBUTOR_NAME";
export const SET_CREDIT = "SET_CREDIT";
export const SET_SUBJECT_TERMS = "SET_SUBJECT_TERMS";
export const SET_KEYWORDS = "SET_KEYWORDS";
export const SET_EDITORIAL_TOPICS = "SET_EDITORIAL_TOPICS";
// export const SET_WITHIN_SUBSCRIPTION = 'SET_WITHIN_SUBSCRIPTION';
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_TIME_LIMIT = "SET_TIME_LIMIT";
export const SET_CREATED_MIN = "SET_CREATED_MIN";
export const SET_CREATED_MAX = "SET_CREATED_MAX";
export const SET_EVENTS = "SET_EVENTS";
export const SET_PERSONS = "SET_PERSONS";
export const SET_PROPER_NAMES = "SET_PROPER_NAMES";
export const SET_PLACES = "SET_PLACES";
export const SET_CITY = "SET_CITY";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PICTURE_ORIENTATION = "SET_PICTURE_ORIENTATION";
export const SET_COLORS = "SET_COLORS";
export const SET_STOCK_COLOR = "SET_STOCK_COLOR";
export const SET_BLACK_AND_WHITE = "SET_BLACK_AND_WHITE";
export const SET_CLEAR_EDGE = "SET_CLEAR_EDGE";
export const SET_CREATIVE_TOPICS = "SET_CREATIVE_TOPICS";
export const SET_CREATIVE_PEOPLE = "SET_CREATIVE_PEOPLE";
export const SET_NUMBER_OF_PEOPLE = "SET_NUMBER_OF_PEOPLE";
export const SET_STOCK_IMAGE_TYPE = "SET_STOCK_IMAGE_TYPE";
export const SET_VIDEO_EDIT = "SET_VIDEO_EDIT";
export const SET_VIDEO_TOPICS = "SET_VIDEO_TOPICS";
export const SET_CUSTOMER_TOPICS = "SET_CUSTOMER_TOPICS";
export const SET_ASSIGNMENT_NUMBERS = "SET_ASSIGNMENT_NUMBERS";
export const SET_VG_TOPICS = "SET_VG_TOPICS";
export const SET_REF_PTRS = "SET_REF_PTRS";
export const CLEAR_CREATED_MIN_MAX = "CLEAR_CREATED_MIN_MAX";
export const CLEAR_SEARCH_FILTER = "CLEAR_SEARCH_FILTER";
export const TOGGLE_MENU = "TOGGLE_MENU";

//Action Creators
export const setOffset = value => ({
  type: SET_OFFSET,
  value
});

export const setSearchAfter = value => ({
  type: SET_SEARCH_AFTER,
  value
});

export const setMainGroup = value => ({
  type: SET_MAIN_GROUP,
  value
});

export const setSortOrder = value => ({
  type: SET_SORT_ORDER,
  value
});

export const setSearchString = value => ({
  type: SET_SEARCH_STRING,
  value
});

export const setTempSearchString = value => ({
  type: SET_TEMP_SEARCH_STRING,
  value
});

export const setPhotographer = value => ({
  type: SET_PHOTOGRAPHER,
  value
});

export const setDistributorName = value => ({
  type: SET_DISTRIBUTOR_NAME,
  value
});

export const setCredit = value => ({
  type: SET_CREDIT,
  value
});

export const setSubjectTerms = value => ({
  type: SET_SUBJECT_TERMS,
  value
});

export const setKeywords = value => ({
  type: SET_KEYWORDS,
  value
});

export const setEditorialTopics = value => ({
  type: SET_EDITORIAL_TOPICS,
  value
});

export const setSubscription = value => ({
  type: SET_SUBSCRIPTION,
  value
});

export const setTimeLimit = value => ({
  type: SET_TIME_LIMIT,
  value
});

export const setCreatedMin = value => ({
  type: SET_CREATED_MIN,
  value
});

export const setCreatedMax = value => ({
  type: SET_CREATED_MAX,
  value
});

export const setEvents = value => ({
  type: SET_EVENTS,
  value
});

export const setPersons = value => ({
  type: SET_PERSONS,
  value
});

export const setProperNames = value => ({
  type: SET_PROPER_NAMES,
  value
});

export const setPlaces = value => ({
  type: SET_PLACES,
  value
});

export const setCity = value => ({
  type: SET_CITY,
  value
});

export const setCountry = value => ({
  type: SET_COUNTRY,
  value
});

export const setPictureOrientation = value => ({
  type: SET_PICTURE_ORIENTATION,
  value
});

export const setColors = value => ({
  type: SET_COLORS,
  value
});

export const setStockColor = value => ({
  type: SET_STOCK_COLOR,
  value
});

export const setBlackAndWhite = value => ({
  type: SET_BLACK_AND_WHITE,
  value
});

export const setClearEdge = value => ({
  type: SET_CLEAR_EDGE,
  value
});

export const setCreativeTopics = value => ({
  type: SET_CREATIVE_TOPICS,
  value
});

export const setCreativePeople = value => ({
  type: SET_CREATIVE_PEOPLE,
  value
});

export const setNumberOfPeople = value => ({
  type: SET_NUMBER_OF_PEOPLE,
  value
});

export const setStockImageType = value => ({
  type: SET_STOCK_IMAGE_TYPE,
  value
});

export const setVideoEdit = value => ({
  type: SET_VIDEO_EDIT,
  value
});

export const setVideoTopics = value => ({
  type: SET_VIDEO_TOPICS,
  value
});

export const setCustomerTopics = value => ({
  type: SET_CUSTOMER_TOPICS,
  value
});

export const setAssignmentNumbers = value => ({
  type: SET_ASSIGNMENT_NUMBERS,
  value
});

export const setVgTopics = value => ({
  type: SET_VG_TOPICS,
  value
});

export const setRefPtrs = value => ({
  type: SET_REF_PTRS,
  value
});

export const clearCreatedMinMax = () => ({
  type: CLEAR_CREATED_MIN_MAX
});

export const clearSearchFilter = () => ({
  type: CLEAR_SEARCH_FILTER
});

export const toggleMenu = () => ({
  type: TOGGLE_MENU
});
