import ScanpixApi from "./index";

export default class LoginAction extends ScanpixApi {
  constructor() {
    super();
    super._setAction("login.action");
  }

  login() {
    const { pathname, search } = window.location;
    let currentPath = pathname;
    if (search && search.trim() !== "") {
      currentPath = `${pathname}${encodeURIComponent(search)}`;
    }

    super._setQuery({ returnTo: currentPath });
    return super._changeLocation();
  }

  logout() {
    super._setQuery({ logout: "" });
    return super._changeLocation();
  }
}
