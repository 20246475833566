import { useTitle } from "react-use";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

const PageTitle = () => {
  const pageTitle = useSelector(state => state.global.pageTitle, isEqual);
  useTitle(pageTitle);
  return null;
};

export default PageTitle;
