const assets = [
  {
    mainGroup: "creative",
    offset: 0,
    credit: "Maskot",
    headline: "Low angle view of man taking selfie with female friends",
    caption: "Low angle view of man taking selfie with female friends",
    previewLocation:
      "https://preview.sdl.no/v1/no/XTuT1cvG58NDB3-xmX9MaQ/LQYHlIEE6dA?v=1622710960929",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2017-10-19 11:38:05",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/V9KClnGFgAydK7aeeFzXOA/LQYHlIEE6dA?v=1622710960929"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/XTuT1cvG58NDB3-xmX9MaQ/LQYHlIEE6dA?v=1622710960929"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/l9KOLDDynvigVsD_vQPEGA/LQYHlIEE6dA?v=1622710960929"
      }
    ],
    highres: [
      {
        fileSize: 8544203,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3804
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA58100.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8544203,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3804,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA58100"],
    businessArea: "editorial",
    refPtr: "LQYHlIEE6dA",
    originalRefPtr: "tc4ded9c",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 1,
    credit: "Maskot",
    headline:
      "Midsection of young female doctor using digital tablet while sitting in corridor at hospital",
    caption:
      "Midsection of young female doctor using digital tablet while sitting in corridor at hospital",
    previewLocation:
      "https://preview.sdl.no/v1/no/3mBt7KIXaF_-Lhyf-AVqtA/RvjyePJcJg0?v=1622710866612",
    lightboxSupport: true,
    byline: "Maskot .",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2018-08-31 16:49:55",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/3mBt7KIXaF_-Lhyf-AVqtA/RvjyePJcJg0?v=1622710866612"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/Kh6sUar8UaUdDlWMJqZsmQ/RvjyePJcJg0?v=1622710866612"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/1_9uvUC4xyQRjuVq_n-r3g/RvjyePJcJg0?v=1622710866612"
      }
    ],
    highres: [
      {
        fileSize: 4673718,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA65273.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 4673718,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA65273"],
    businessArea: "editorial",
    refPtr: "RvjyePJcJg0",
    originalRefPtr: "td1eade9",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 2,
    credit: "Maskot",
    headline: "Full length of friends shopping together for jackets at clothing store",
    caption: "Full length of friends shopping together for jackets at clothing store",
    previewLocation:
      "https://preview.sdl.no/v1/no/Ix5GPMBNBXfNz96NQPzCjA/s60H7Au5OHA?v=1622710977095",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-05 14:45:20",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/27H9NIZXtHN1E_oSQSrRCg/s60H7Au5OHA?v=1622710977095"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/Ix5GPMBNBXfNz96NQPzCjA/s60H7Au5OHA?v=1622710977095"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/kY5v-vi5CMlqj7xSvKfVdA/s60H7Au5OHA?v=1622710977095"
      }
    ],
    highres: [
      {
        fileSize: 4609912,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3800,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA38633.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 4609912,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3800
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    assignmentNumber: ["MA38633"],
    businessArea: "editorial",
    refPtr: "s60H7Au5OHA",
    originalRefPtr: "tad8145a",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 3,
    credit: "Maskot",
    headline: "Smiling blond teenage boy at desk in classroom",
    caption: "Smiling blond teenage boy at desk in classroom",
    previewLocation:
      "https://preview.sdl.no/v1/no/mGl1LAYyC81YZ2JpHPNsbw/mMFr4KNwXMs?v=1633345374395",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2021-10-04 10:48:42",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/_mqZCML3SerAVRM1fwstlg/mMFr4KNwXMs?v=1633345374395"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/mGl1LAYyC81YZ2JpHPNsbw/mMFr4KNwXMs?v=1633345374395"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/-Px9BshSK8Dx00cVY_PzWw/mMFr4KNwXMs?v=1633345374395"
      }
    ],
    highres: [
      {
        fileSize: 8029378,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3800,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA89116.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8029378,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3800
      }
    ],
    subjectTerms: [],
    editable: false,
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "mMFr4KNwXMs",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 4,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/Pgiu3er2pf5X6ShrZ4kStg/b7jZfGEQTPo?v=1613790415427",
    lightboxSupport: true,
    byline: "Jörgen Wiklund/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-06 12:40:59",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/K5QOdHTVd1NJMug0QOiiDA/b7jZfGEQTPo?v=1613790415427"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/Pgiu3er2pf5X6ShrZ4kStg/b7jZfGEQTPo?v=1613790415427"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/dCFrYFj_8rz457xb_Cfs_g/b7jZfGEQTPo?v=1613790415427"
      }
    ],
    highres: [
      {
        fileSize: 11177318,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5079,
        height: 3386
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_HKDX",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 11177318,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3386,
        exampleWidth: 5079
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "b7jZfGEQTPo",
    originalRefPtr: "tabe6d19",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 5,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/6ObGG4R7qyNo83Kmtqkgcw/oK1d1DVAdYw?v=1613812067022",
    lightboxSupport: true,
    byline: "Victoria Khatib/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-03 12:16:50",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/6ObGG4R7qyNo83Kmtqkgcw/oK1d1DVAdYw?v=1613812067022"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/4bVQBH5MrFG8bfNC-2dk9g/oK1d1DVAdYw?v=1613812067022"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/Spd9fDsINzzBh6FpaarPBQ/oK1d1DVAdYw?v=1613812067022"
      }
    ],
    highres: [
      {
        fileSize: 7348023,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3307,
        height: 4961
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_VR5G",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 7348023,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 4961,
        exampleWidth: 3307
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "oK1d1DVAdYw",
    originalRefPtr: "tabd1e90",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 6,
    credit: "Maskot",
    headline: "Side view of industrial designer analyzing product at home office",
    caption: "Side view of industrial designer analyzing product at home office",
    previewLocation:
      "https://preview.sdl.no/v1/no/sJeqcFU5YXN6zp0LubfkZw/QuhNngXqJCs?v=1622710862974",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-06-13 20:24:12",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/sJeqcFU5YXN6zp0LubfkZw/QuhNngXqJCs?v=1622710862974"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/gFcZntpBGuFXPBeU9o-MEg/QuhNngXqJCs?v=1622710862974"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/ldSvKuPIDsUzLyDoF3TcRQ/QuhNngXqJCs?v=1622710862974"
      }
    ],
    highres: [
      {
        fileSize: 3292324,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA47868.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 3292324,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA47868"],
    businessArea: "editorial",
    refPtr: "QuhNngXqJCs",
    originalRefPtr: "tb2bfbc2",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 7,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/5umAIJYNTmLBtQoEvSW_Zg/4rBOFcTO9yw?v=1613809209624",
    lightboxSupport: true,
    byline: "Emma Eriksson/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-14 03:23:06",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/w-GUNKZ7qCDI8unwuugbbA/4rBOFcTO9yw?v=1613809209624"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/6EIponvXUXsG7LXQk9b_PQ/4rBOFcTO9yw?v=1613809209624"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/5umAIJYNTmLBtQoEvSW_Zg/4rBOFcTO9yw?v=1613809209624"
      }
    ],
    highres: [
      {
        fileSize: 9660592,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3386,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_HC0X",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 9660592,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3386
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "4rBOFcTO9yw",
    originalRefPtr: "tac243ab",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 8,
    credit: "Maskot",
    headline:
      "Male entrepreneur using phone while looking at female colleague writing on clipboard in boutique",
    caption:
      "Male entrepreneur using phone while looking at female colleague writing on clipboard in boutique",
    previewLocation:
      "https://preview.sdl.no/v1/no/8PKyvRtMfEeAxacTM6ledA/848H_ibtiDg?v=1622710929084",
    lightboxSupport: true,
    byline: "Kentaroo Tryman",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2018-11-13 11:15:02",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/w-e431Jf8gxOR2x2wws0bQ/848H_ibtiDg?v=1622710929084"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/G6_M84h-RPaIWKntZtvXnQ/848H_ibtiDg?v=1622710929084"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/8PKyvRtMfEeAxacTM6ledA/848H_ibtiDg?v=1622710929084"
      }
    ],
    highres: [
      {
        fileSize: 7353318,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA66330.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 7353318,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA66330"],
    businessArea: "editorial",
    refPtr: "848H_ibtiDg",
    originalRefPtr: "td5b009f",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 9,
    credit: "Maskot",
    headline: "Male student on the phone",
    caption: "Male student on the phone",
    previewLocation:
      "https://preview.sdl.no/v1/no/T82dlHjucX9lF_yd0MvLHQ/9f2LoaY2n5I?v=1622710860302",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-04 17:15:41",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/e28Las5ovlJpC6I940D94A/9f2LoaY2n5I?v=1622710860302"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/T82dlHjucX9lF_yd0MvLHQ/9f2LoaY2n5I?v=1622710860302"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/utP8iprgHofhSs_djBs0Bw/9f2LoaY2n5I?v=1622710860302"
      }
    ],
    highres: [
      {
        fileSize: 3819593,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3786,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA16942.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 3819593,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3786
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    assignmentNumber: ["MA16942"],
    businessArea: "editorial",
    refPtr: "9f2LoaY2n5I",
    originalRefPtr: "tad763d6",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 10,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/-xnB_5zoW4mfocwW7nQ8jQ/_CgN09FYSKU?v=1617023888429",
    lightboxSupport: true,
    byline: "Justem Johnsson/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-08 15:55:35",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/ly8c08o0nO9OEhpgxAP1jw/_CgN09FYSKU?v=1617023888429"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/-xnB_5zoW4mfocwW7nQ8jQ/_CgN09FYSKU?v=1617023888429"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/3-w9MvsGWK6MJ_VD0qqXtg/_CgN09FYSKU?v=1617023888429"
      }
    ],
    highres: [
      {
        fileSize: 11121633,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5079,
        height: 3386
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_WUQK",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 11121633,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3386,
        exampleWidth: 5079
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "_CgN09FYSKU",
    originalRefPtr: "tabf765c",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 11,
    credit: "Maskot",
    headline: "Boy cleaning cabinet in living room at home",
    caption: "Boy cleaning cabinet in living room at home",
    previewLocation:
      "https://preview.sdl.no/v1/no/8aPMtVa2IGfUJizezYeSmg/uMTAbgInjEg?v=1622710937808",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2017-03-15 15:30:21",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/MgSMWgHMaPbNk0WI5Ob3AA/uMTAbgInjEg?v=1622710937808"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/8aPMtVa2IGfUJizezYeSmg/uMTAbgInjEg?v=1622710937808"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/fMEGta8GWHUXVdUDtorDGw/uMTAbgInjEg?v=1622710937808"
      }
    ],
    highres: [
      {
        fileSize: 5610975,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA54005.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 5610975,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA54005"],
    businessArea: "editorial",
    refPtr: "uMTAbgInjEg",
    originalRefPtr: "tbc1f07f",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 12,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/gjOTrk_WS54CsmRW6y-PIQ/Alfqxb-VytQ?v=1613779016427",
    lightboxSupport: true,
    byline: "David Zandén/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-06 14:07:33",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/qw5VGelHDjMuo0aLFuSt_A/Alfqxb-VytQ?v=1613779016427"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/gjOTrk_WS54CsmRW6y-PIQ/Alfqxb-VytQ?v=1613779016427"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/rMNzI6CWH5SJ7K7ixOJYdQ/Alfqxb-VytQ?v=1613779016427"
      }
    ],
    highres: [
      {
        fileSize: 10818874,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5079,
        height: 3390
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_D8YF",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 10818874,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3390,
        exampleWidth: 5079
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "Alfqxb-VytQ",
    originalRefPtr: "tabe7632",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 13,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/yie5scRHqyprHp1NBbLWWA/GgYcnLE_pug?v=1613773401443",
    lightboxSupport: true,
    byline: "Anne Dillner/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-09 02:56:33",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/yie5scRHqyprHp1NBbLWWA/GgYcnLE_pug?v=1613773401443"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/3Yw_MluXS824RPiayfe3vQ/GgYcnLE_pug?v=1613773401443"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/pmeXLBtkGSxOsZ9t5Fh4gA/GgYcnLE_pug?v=1613773401443"
      }
    ],
    highres: [
      {
        fileSize: 16648613,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3386,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_7RKC",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 16648613,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3386
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "GgYcnLE_pug",
    originalRefPtr: "tabfc069",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 14,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/Y3N93LYxRO0ef8i8nTm-rQ/s0Ys2f5Aldw?v=1613795428935",
    lightboxSupport: true,
    byline: "Mikael Svensson/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-02 20:48:21",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/Y3N93LYxRO0ef8i8nTm-rQ/s0Ys2f5Aldw?v=1613795428935"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/6sLlbheiWjC-ROcVrR69lg/s0Ys2f5Aldw?v=1613795428935"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/Hf4xdDeD3JKkqL9S3h3NBA/s0Ys2f5Aldw?v=1613795428935"
      }
    ],
    highres: [
      {
        fileSize: 5817513,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3406,
        height: 5120
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_RS9Q",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 5817513,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5120,
        exampleWidth: 3406
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "s0Ys2f5Aldw",
    originalRefPtr: "tabcd34e",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 15,
    credit: "Maskot",
    headline: "Portrait of happy multi ethnic friends standing together",
    caption: "Portrait of happy multi ethnic friends standing together",
    previewLocation:
      "https://preview.sdl.no/v1/no/NrI8Zw9wuW1fYQY0yyOZdA/XZjKj5gVk5k?v=1622710881609",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-05 11:14:33",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/NrI8Zw9wuW1fYQY0yyOZdA/XZjKj5gVk5k?v=1622710881609"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/BXzcep1udK74sTzO1SNjCw/XZjKj5gVk5k?v=1622710881609"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/MyVg3z7eQfykXuOSOOnt6A/XZjKj5gVk5k?v=1622710881609"
      }
    ],
    highres: [
      {
        fileSize: 9711930,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA35951.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 9711930,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA35951"],
    businessArea: "editorial",
    refPtr: "XZjKj5gVk5k",
    originalRefPtr: "tad7f077",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 16,
    credit: "Maskot",
    headline: "Two streetcars going side by side",
    caption: "Two streetcars going side by side",
    previewLocation:
      "https://preview.sdl.no/v1/no/s3bXytbEACTx_CO9apEeDw/g25bWEWAQ34?v=1622710891406",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-04 13:49:48",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/s3bXytbEACTx_CO9apEeDw/g25bWEWAQ34?v=1622710891406"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/qZIl6L2YsZ9COcN3AwMeAA/g25bWEWAQ34?v=1622710891406"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/7muMY_EsNNvOOLpr6fIvng/g25bWEWAQ34?v=1622710891406"
      }
    ],
    highres: [
      {
        fileSize: 7345688,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5138,
        height: 3399
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA14318.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 7345688,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3399,
        exampleWidth: 5138
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA14318"],
    businessArea: "editorial",
    refPtr: "g25bWEWAQ34",
    originalRefPtr: "tad7442f",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 17,
    credit: "Maskot",
    headline: "Side view of young man picking book from table while standing at home",
    caption: "Side view of young man picking book from table while standing at home",
    previewLocation:
      "https://preview.sdl.no/v1/no/nms6ZqRO-T8XUUtz0lI5TQ/IsEkF9ZpEzA?v=1622710886234",
    lightboxSupport: true,
    byline: "Maskot Bildbyrå",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2019-09-27 10:23:16",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/flK8B-CogWNVIguBfdzYiA/IsEkF9ZpEzA?v=1622710886234"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/nms6ZqRO-T8XUUtz0lI5TQ/IsEkF9ZpEzA?v=1622710886234"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/A1aGV6icgXIoUhFinBBA2g/IsEkF9ZpEzA?v=1622710886234"
      }
    ],
    highres: [
      {
        fileSize: 9608413,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA71186.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 9608413,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "IsEkF9ZpEzA",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 18,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/0dG4jFXu7dTwVd7tj-oBpw/xIW3l42x9KE?v=1617028562337",
    lightboxSupport: true,
    byline: "Stephan Berglund/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-02 19:40:11",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/DLriVjvkCZ0axIiPJvA22w/xIW3l42x9KE?v=1617028562337"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/8fIvcwQUBMWkoS8nOQuJhQ/xIW3l42x9KE?v=1617028562337"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/0dG4jFXu7dTwVd7tj-oBpw/xIW3l42x9KE?v=1617028562337"
      }
    ],
    highres: [
      {
        fileSize: 5999450,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 4961,
        height: 3721
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_798A",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 5999450,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3721,
        exampleWidth: 4961
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "xIW3l42x9KE",
    originalRefPtr: "tabccc8c",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 19,
    credit: "Maskot",
    headline: "Smiling couple using smart phone while sitting in restaurant",
    caption: "Smiling couple using smart phone while sitting in restaurant",
    previewLocation:
      "https://preview.sdl.no/v1/no/s3vpQVKzeuVfAHvY_RRC2A/knulNyHgy4A?v=1622710935106",
    lightboxSupport: true,
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2019-10-14 11:07:31",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/uMPvQn15xdIeM_rvOj1d6w/knulNyHgy4A?v=1622710935106"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/s3vpQVKzeuVfAHvY_RRC2A/knulNyHgy4A?v=1622710935106"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/Q9eWGFyVhFWjAJVoUIVDSw/knulNyHgy4A?v=1622710935106"
      }
    ],
    highres: [
      {
        fileSize: 8097802,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA73347.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8097802,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "knulNyHgy4A",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 20,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/FUgWeBdYcudLga83w7alDQ/_VFT0-Tr6c4?v=1613811863327",
    lightboxSupport: true,
    byline: "Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-03-01 14:15:06",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/FUgWeBdYcudLga83w7alDQ/_VFT0-Tr6c4?v=1613811863327"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/r34y1EvO6JCn2971SIA4Ew/_VFT0-Tr6c4?v=1613811863327"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/efJRWH-F-k0M5PxRyNCSLw/_VFT0-Tr6c4?v=1613811863327"
      }
    ],
    highres: [
      {
        fileSize: 6460855,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5079,
        height: 3386
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_0T2E",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 6460855,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3386,
        exampleWidth: 5079
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "_VFT0-Tr6c4",
    originalRefPtr: "tae5f621",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 21,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/KfCLQsmsbCPCkgfV79D1SQ/4EboxERqX94?v=1613782876956",
    lightboxSupport: true,
    byline: "Rebecca Wallin/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-06 16:54:57",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/CDDY3Ky5QoO9uLgt7BZR0g/4EboxERqX94?v=1613782876956"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/4aWUfiJLUkUP5vLTB-Pu1Q/4EboxERqX94?v=1613782876956"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/KfCLQsmsbCPCkgfV79D1SQ/4EboxERqX94?v=1613782876956"
      }
    ],
    highres: [
      {
        fileSize: 7445197,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3379,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_5XUZ",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 7445197,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3379
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "4EboxERqX94",
    originalRefPtr: "tabe8989",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 22,
    credit: "Maskot",
    headline: "Mature businessman using smart phone while walking with bag on sidewalk in city",
    caption: "Mature businessman using smart phone while walking with bag on sidewalk in city",
    previewLocation:
      "https://preview.sdl.no/v1/no/gU53snqmrP0jlMdeRpWO2g/5TvIwY_XEGQ?v=1622710877642",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2017-12-15 17:49:33",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/gU53snqmrP0jlMdeRpWO2g/5TvIwY_XEGQ?v=1622710877642"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/Dalzs6uBLl-S3xEY1w0-YQ/5TvIwY_XEGQ?v=1622710877642"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/zhdhbQPO7PyBXiSPDNKsMw/5TvIwY_XEGQ?v=1622710877642"
      }
    ],
    highres: [
      {
        fileSize: 8235789,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3804,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA60020.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8235789,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3804
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    assignmentNumber: ["MA60020"],
    businessArea: "editorial",
    refPtr: "5TvIwY_XEGQ",
    originalRefPtr: "tc73349a",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 23,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/WM3-ff_o9f_w534WreIC-g/1hv78Za-zoE?v=1613805164328",
    lightboxSupport: true,
    byline: "Leif Johansson/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-03 09:43:52",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/I8-PDp175qg0XZ0O7Ywd3A/1hv78Za-zoE?v=1613805164328"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/WM3-ff_o9f_w534WreIC-g/1hv78Za-zoE?v=1613805164328"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/aZxR-890iYYJOMfNBqUE4g/1hv78Za-zoE?v=1613805164328"
      }
    ],
    highres: [
      {
        fileSize: 9483927,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5120,
        height: 3537
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_S6UY",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 9483927,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3537,
        exampleWidth: 5120
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "1hv78Za-zoE",
    originalRefPtr: "tabd0e03",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 24,
    credit: "Maskot",
    headline: "Male and female students looking at digital tablet in classroom",
    caption: "Male and female students looking at digital tablet in classroom",
    previewLocation:
      "https://preview.sdl.no/v1/no/HL4vJC8K88dsGa-hgxmMog/7fciKYBIZzo?v=1622710901074",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2019-04-30 16:24:35",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/vxcyJAPb3zcOuS7xfU3Brg/7fciKYBIZzo?v=1622710901074"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/KFl86bEmCqWCrlBNDZlHug/7fciKYBIZzo?v=1622710901074"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/HL4vJC8K88dsGa-hgxmMog/7fciKYBIZzo?v=1622710901074"
      }
    ],
    highres: [
      {
        fileSize: 6333526,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA70208.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 6333526,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA70208"],
    businessArea: "editorial",
    refPtr: "7fciKYBIZzo",
    originalRefPtr: "tdbf5fa6",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 25,
    credit: "Maskot",
    headline: "Cropped image of woman planting in garden",
    caption: "Cropped image of woman planting in garden",
    previewLocation:
      "https://preview.sdl.no/v1/no/2Tykm7zxdTCEGfFmZfnWNA/sFJjT9dnuOU?v=1622710905538",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-05 15:28:46",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/5fRM1vQe6YbihXFuB5EBHw/sFJjT9dnuOU?v=1622710905538"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/9p9eIZ6f51JQYcHeq2Busw/sFJjT9dnuOU?v=1622710905538"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/2Tykm7zxdTCEGfFmZfnWNA/sFJjT9dnuOU?v=1622710905538"
      }
    ],
    highres: [
      {
        fileSize: 3844208,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3800,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA39161.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 3844208,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3800
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    assignmentNumber: ["MA39161"],
    businessArea: "editorial",
    refPtr: "sFJjT9dnuOU",
    originalRefPtr: "tad81cd4",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 26,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/z1Rff9-Aq73NHh7vfeJ8tw/cOjirdGUGr8?v=1613805213920",
    lightboxSupport: true,
    byline: "Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-14 02:12:44",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/6uUdwwVqTeEPg0cl8FnwMQ/cOjirdGUGr8?v=1613805213920"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/04MQVeyIKXheNICpOPoG4g/cOjirdGUGr8?v=1613805213920"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/z1Rff9-Aq73NHh7vfeJ8tw/cOjirdGUGr8?v=1613805213920"
      }
    ],
    highres: [
      {
        fileSize: 9569362,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3386,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_T1KE",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 9569362,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3386
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "cOjirdGUGr8",
    originalRefPtr: "tac23d56",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 27,
    credit: "Maskot",
    headline: "Female colleagues listening to businesswoman in meeting at conference event",
    caption: "Female colleagues listening to businesswoman in meeting at conference event",
    previewLocation:
      "https://preview.sdl.no/v1/no/UdnVzi__HSOZmc7gsG2wYw/UIZaQUiRExo?v=1622710866760",
    lightboxSupport: true,
    byline: "Maskot Bildbyrå",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2019-09-27 09:51:55",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/UdnVzi__HSOZmc7gsG2wYw/UIZaQUiRExo?v=1622710866760"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/ZqJIUTfCsiwO_1QnrfTOcg/UIZaQUiRExo?v=1622710866760"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/CyqFd7RaSODNjhLvxblqIA/UIZaQUiRExo?v=1622710866760"
      }
    ],
    highres: [
      {
        fileSize: 5391098,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA71481.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 5391098,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "UIZaQUiRExo",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 28,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/mg9n1BwV6RLdI4ajNCBFCw/SgJvt_p9-0Y?v=1613813642927",
    lightboxSupport: true,
    byline: "Anne Dillner/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-09 07:50:34",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/mg9n1BwV6RLdI4ajNCBFCw/SgJvt_p9-0Y?v=1613813642927"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/H2FmCNXyAB0U2Fohztodlg/SgJvt_p9-0Y?v=1613813642927"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/zRmgcgbsf9dYX-1iYqDmcQ/SgJvt_p9-0Y?v=1613813642927"
      }
    ],
    highres: [
      {
        fileSize: 8446165,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3386,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_XOJ6",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8446165,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3386
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "SgJvt_p9-0Y",
    originalRefPtr: "tabfe151",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 29,
    credit: "Maskot",
    headline: "Close-up of various beer in glasses at bar counter",
    caption: "Close-up of various beer in glasses at bar counter",
    previewLocation:
      "https://preview.sdl.no/v1/no/kPjy0FrENpdXvY_jOWjByA/SewRnd33pX4?v=1622710896096",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2018-04-25 12:04:35",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/7t_VeNUhs30Ct4CTH15S5g/SewRnd33pX4?v=1622710896096"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/BCLm4XxqOpU4M67lN-_quA/SewRnd33pX4?v=1622710896096"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/kPjy0FrENpdXvY_jOWjByA/SewRnd33pX4?v=1622710896096"
      }
    ],
    highres: [
      {
        fileSize: 12448360,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA62520.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 12448360,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA62520"],
    businessArea: "editorial",
    refPtr: "SewRnd33pX4",
    originalRefPtr: "tcc55a15",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 30,
    credit: "Maskot",
    headline: "Male chef preparing food in kitchen at restaurant",
    caption: "Male chef preparing food in kitchen at restaurant",
    previewLocation:
      "https://preview.sdl.no/v1/no/DePgjUN6cKv9tpvsicrMTg/90clnh_rolE?v=1622710959771",
    lightboxSupport: true,
    byline: "Maskot Bildbyrå",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2021-05-31 16:54:23",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/DePgjUN6cKv9tpvsicrMTg/90clnh_rolE?v=1622710959771"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/SFKhnFggyE61LvgRubixSQ/90clnh_rolE?v=1622710959771"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/QuflqTkWqIS0uRtGnR9YZg/90clnh_rolE?v=1622710959771"
      }
    ],
    highres: [
      {
        fileSize: 9582258,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3800,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA86820.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 9582258,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3800
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "90clnh_rolE",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 31,
    credit: "Maskot",
    headline: "Smiling female entrepreneur looking away in office",
    caption: "Smiling female entrepreneur looking away in office",
    previewLocation:
      "https://preview.sdl.no/v1/no/aneRFji41OUj5eqJw4bvKQ/jSmU7BOG0-I?v=1622710932609",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2021-04-29 14:34:27",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/ee47Y1VysqYHlebf5KSz9w/jSmU7BOG0-I?v=1622710932609"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/aneRFji41OUj5eqJw4bvKQ/jSmU7BOG0-I?v=1622710932609"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/yUv-7LIpC2smbg6UhMkDNw/jSmU7BOG0-I?v=1622710932609"
      }
    ],
    highres: [
      {
        fileSize: 8856534,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA86180.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8856534,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "jSmU7BOG0-I",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 32,
    credit: "Maskot",
    headline: "Senior man with laptop reaching for newspaper at table",
    caption: "Senior man with laptop reaching for newspaper at table",
    previewLocation:
      "https://preview.sdl.no/v1/no/0xaaY9wgw70EPbDVNgNqCA/s37_kl5S5CY?v=1622710935771",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-05-31 17:04:00",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/aqM7hO_KyWoESDTb4ROOvQ/s37_kl5S5CY?v=1622710935771"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/VYGvwsgedjeweB5pnjHH3A/s37_kl5S5CY?v=1622710935771"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/0xaaY9wgw70EPbDVNgNqCA/s37_kl5S5CY?v=1622710935771"
      }
    ],
    highres: [
      {
        fileSize: 3943525,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA48374.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 3943525,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA48374"],
    businessArea: "editorial",
    refPtr: "s37_kl5S5CY",
    originalRefPtr: "tb2532d1",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 33,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/DGdW4OgnJZJCk99YzwA3RQ/_vyI-tVyV7c?v=1613774411427",
    lightboxSupport: true,
    byline: "Fabian Fogelberg/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-13 23:50:05",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/UMmShB8_EEams-kUlrNbHg/_vyI-tVyV7c?v=1613774411427"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/DGdW4OgnJZJCk99YzwA3RQ/_vyI-tVyV7c?v=1613774411427"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/OvCtW-NGfbIoKD4WU1DSug/_vyI-tVyV7c?v=1613774411427"
      }
    ],
    highres: [
      {
        fileSize: 7747732,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3386,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_LXSH",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 7747732,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3386
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "_vyI-tVyV7c",
    originalRefPtr: "tac23009",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 34,
    credit: "Maskot",
    headline:
      "Food in bowl by plate and drinking glass arranged on dining table during social gathering",
    caption:
      "Food in bowl by plate and drinking glass arranged on dining table during social gathering",
    previewLocation:
      "https://preview.sdl.no/v1/no/g5V5Ny33BnViVa39tUTEOg/xBmlGyhrf0c?v=1622710865740",
    lightboxSupport: true,
    byline: "Maskot Bildbyrå",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2020-12-21 18:25:38",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/g5V5Ny33BnViVa39tUTEOg/xBmlGyhrf0c?v=1622710865740"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/oXZNZRqxm7Peoz2NmpJRPQ/xBmlGyhrf0c?v=1622710865740"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/8zhENRc44UcpC3lSlmGnbg/xBmlGyhrf0c?v=1622710865740"
      }
    ],
    highres: [
      {
        fileSize: 14489774,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA83210.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 14489774,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "xBmlGyhrf0c",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 35,
    credit: "Maskot",
    headline: "Senior man repairing sconce at home",
    caption: "Senior man repairing sconce at home",
    previewLocation:
      "https://preview.sdl.no/v1/no/8TKkVCYNPmym1CeOEgQ2Ug/7uExbuDpqDc?v=1622710919438",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2017-06-30 11:06:44",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/qQO5XDSKq-vVKXmmZwQjww/7uExbuDpqDc?v=1622710919438"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/sxLnjJdQldFVrqpjYGX9tg/7uExbuDpqDc?v=1622710919438"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/8TKkVCYNPmym1CeOEgQ2Ug/7uExbuDpqDc?v=1622710919438"
      }
    ],
    highres: [
      {
        fileSize: 8613690,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3798,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA56310.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8613690,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3798
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    assignmentNumber: ["MA56310"],
    businessArea: "editorial",
    refPtr: "7uExbuDpqDc",
    originalRefPtr: "tc094fd6",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 36,
    credit: "Maskot",
    headline: "Full length of couple taking selfie on smart phone at beach against sky",
    caption: "Full length of couple taking selfie on smart phone at beach against sky",
    previewLocation:
      "https://preview.sdl.no/v1/no/l4pdgFq97KExjkWKl7733Q/LXI0UNNHRK8?v=1622710887551",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2019-12-19 15:18:23",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/pkgcZOVC94PChLhjJXYccw/LXI0UNNHRK8?v=1622710887551"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/daRVypH0ZUOvnJ-9zqkpvA/LXI0UNNHRK8?v=1622710887551"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/l4pdgFq97KExjkWKl7733Q/LXI0UNNHRK8?v=1622710887551"
      }
    ],
    highres: [
      {
        fileSize: 7490737,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3804
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA75175.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 7490737,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3804,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "LXI0UNNHRK8",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 37,
    credit: "Maskot",
    headline:
      "Side view of woman lying on hammock while looking at male hanging clothes on tree in forest",
    caption:
      "Side view of woman lying on hammock while looking at male hanging clothes on tree in forest",
    previewLocation:
      "https://preview.sdl.no/v1/no/FFvFnSxkFXlY8RZYZl9tdg/G8UZAMUX02o?v=1622710912209",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2020-11-03 13:01:01",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/FFvFnSxkFXlY8RZYZl9tdg/G8UZAMUX02o?v=1622710912209"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/Mth3RIH2HIj4HxXwapAOTg/G8UZAMUX02o?v=1622710912209"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/Y2VZdegyimk0zonEFrtmaw/G8UZAMUX02o?v=1622710912209"
      }
    ],
    highres: [
      {
        fileSize: 14553995,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA82628.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 14553995,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "G8UZAMUX02o",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 38,
    credit: "Maskot",
    headline: "Mature businesswoman talking with female colleague in office",
    caption: "Mature businesswoman talking with female colleague in office",
    previewLocation:
      "https://preview.sdl.no/v1/no/qS-tWSxE95jMmerMuC_snw/JzR3V9IDuYc?v=1622710911833",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-06 00:19:33",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/OlOY_f62o06Rjs-kPVoAVA/JzR3V9IDuYc?v=1622710911833"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/7jUB7lcnfdAWum3h6QnHzQ/JzR3V9IDuYc?v=1622710911833"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/qS-tWSxE95jMmerMuC_snw/JzR3V9IDuYc?v=1622710911833"
      }
    ],
    highres: [
      {
        fileSize: 1701349,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA46716.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 1701349,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA46716"],
    businessArea: "editorial",
    refPtr: "JzR3V9IDuYc",
    originalRefPtr: "tad86c84",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 39,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/yOh2CMEnHIZHGHLjzTYxOw/jH2tsxHhisM?v=1613816660747",
    lightboxSupport: true,
    byline: "Rebecka Rynefelt/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-10 02:39:01",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/3AzAaNHVR2bLL9Myos7Fzw/jH2tsxHhisM?v=1613816660747"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/yOh2CMEnHIZHGHLjzTYxOw/jH2tsxHhisM?v=1613816660747"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/MuH3YgkON_vq3mtru7k3OA/jH2tsxHhisM?v=1613816660747"
      }
    ],
    highres: [
      {
        fileSize: 8519548,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5079,
        height: 3386
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_7FBI",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8519548,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3386,
        exampleWidth: 5079
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "jH2tsxHhisM",
    originalRefPtr: "tac059fa",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 40,
    credit: "Maskot",
    headline: "Old man and young man drinking coffee",
    caption: "Old man and young man drinking coffee",
    previewLocation:
      "https://preview.sdl.no/v1/no/XOYYyq8NPwp79irqU6BGaA/ScCPp0g_m6c?v=1622710882704",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-05 00:42:29",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/FW-lGdz2eGj1FYz8t5rDZw/ScCPp0g_m6c?v=1622710882704"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/XOYYyq8NPwp79irqU6BGaA/ScCPp0g_m6c?v=1622710882704"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/jdM5zW0DPyp4YpfMgaq_Sw/ScCPp0g_m6c?v=1622710882704"
      }
    ],
    highres: [
      {
        fileSize: 3352232,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3800,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA22506.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 3352232,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3800
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    assignmentNumber: ["MA22506"],
    businessArea: "editorial",
    refPtr: "ScCPp0g_m6c",
    originalRefPtr: "tad7a583",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 41,
    credit: "Maskot",
    headline: "Side view of smiling young man cheering with eyes closed in sports field",
    caption: "Side view of smiling young man cheering with eyes closed in sports field",
    previewLocation:
      "https://preview.sdl.no/v1/no/-3kpJtGbNq7gBmROq1dTxg/ugyCCGlbv2Y?v=1622710918075",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2020-11-03 12:14:26",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/ou-fN7rw4Ev8_O_fuspeQg/ugyCCGlbv2Y?v=1622710918075"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/-3kpJtGbNq7gBmROq1dTxg/ugyCCGlbv2Y?v=1622710918075"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/vytQr_Yy2dboQW9Ci0jqDw/ugyCCGlbv2Y?v=1622710918075"
      }
    ],
    highres: [
      {
        fileSize: 11829497,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA82268.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 11829497,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "ugyCCGlbv2Y",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 42,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/s2slHjO1Lk2jbhpkUx9Tkw/f9kzE34-ku4?v=1613817347333",
    lightboxSupport: true,
    byline: "Therese Winberg/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-06 15:26:24",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/-9m0TMkS604Np0OWrxZMuA/f9kzE34-ku4?v=1613817347333"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/A3TrHBtbnNGxHviINL5Ueg/f9kzE34-ku4?v=1613817347333"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/s2slHjO1Lk2jbhpkUx9Tkw/f9kzE34-ku4?v=1613817347333"
      }
    ],
    highres: [
      {
        fileSize: 10883933,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3386,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_QQD2",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 10883933,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3386
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "f9kzE34-ku4",
    originalRefPtr: "tabe7f7e",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 43,
    credit: "Maskot",
    headline: "Barefoot",
    caption: "Barefoot",
    previewLocation:
      "https://preview.sdl.no/v1/no/taoexDL8UDSNZZEX3rH0Hw/uaoI2x5VuRE?v=1622710978839",
    lightboxSupport: true,
    byline: "Peter Melander",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-02-06 02:22:25",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/9WsFnum2jhw8MDYMmolvdQ/uaoI2x5VuRE?v=1622710978839"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/CnWzIb__g_gcMskcFU_xeA/uaoI2x5VuRE?v=1622710978839"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/taoexDL8UDSNZZEX3rH0Hw/uaoI2x5VuRE?v=1622710978839"
      }
    ],
    highres: [
      {
        fileSize: 4379553,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3456,
        height: 5054
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA9429.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 4379553,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5054,
        exampleWidth: 3456
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    assignmentNumber: ["MA9429"],
    businessArea: "editorial",
    refPtr: "uaoI2x5VuRE",
    originalRefPtr: "tad8788e",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 44,
    credit: "Maskot",
    headline: "Man using mobile phone while having drink at sidewalk cafe",
    caption: "Man using mobile phone while having drink at sidewalk cafe",
    previewLocation:
      "https://preview.sdl.no/v1/no/9yxNmjapUKZ8dpn38xg3NA/6QccOMlxe5U?v=1622710861139",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2017-02-07 14:40:29",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/c2zuo0-6rNvxyhQEoC1r6g/6QccOMlxe5U?v=1622710861139"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/9yxNmjapUKZ8dpn38xg3NA/6QccOMlxe5U?v=1622710861139"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/wyyaYexyYKdMt9kGywsWag/6QccOMlxe5U?v=1622710861139"
      }
    ],
    highres: [
      {
        fileSize: 6909622,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA53355.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 6909622,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    assignmentNumber: ["MA53355"],
    businessArea: "editorial",
    refPtr: "6QccOMlxe5U",
    originalRefPtr: "tbac128a",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 45,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/6xDygOk4GIxL4aNy6B-xRQ/lhaTnBSUhbw?v=1613804374464",
    lightboxSupport: true,
    byline: "Anne Dillner/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2016-03-01 16:36:00",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/999BKcgsXSodb6FSxIUzmg/lhaTnBSUhbw?v=1613804374464"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/HT5O1LW7mOp-3JqXouGujw/lhaTnBSUhbw?v=1613804374464"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/6xDygOk4GIxL4aNy6B-xRQ/lhaTnBSUhbw?v=1613804374464"
      }
    ],
    highres: [
      {
        fileSize: 11348098,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3386,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_F9UI",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 11348098,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3386
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "lhaTnBSUhbw",
    originalRefPtr: "tae60b2d",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 46,
    credit: "Maskot",
    headline:
      "Working businesswoman drinking coffee while carrying yawning daughter in dining room",
    caption: "Working businesswoman drinking coffee while carrying yawning daughter in dining room",
    previewLocation:
      "https://preview.sdl.no/v1/no/vgpvnNWMyylETUSs_Avc-w/OILR0jn3CHE?v=1622710885797",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2019-09-27 11:02:21",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/vgpvnNWMyylETUSs_Avc-w/OILR0jn3CHE?v=1622710885797"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/8GCLarzL5-DRfMOY4GEN1A/OILR0jn3CHE?v=1622710885797"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/XEZUzfj4VSDN01UznNpeUQ/OILR0jn3CHE?v=1622710885797"
      }
    ],
    highres: [
      {
        fileSize: 8017036,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3800,
        height: 5700
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA71932.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8017036,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5700,
        exampleWidth: 3800
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "OILR0jn3CHE",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 47,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/-UrM-ukNSvS-U493BsG7TA/FeBhgoXMQ3A?v=1613806802261",
    lightboxSupport: true,
    byline: "Linnéa Pettersson/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-09 06:05:40",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/-UrM-ukNSvS-U493BsG7TA/FeBhgoXMQ3A?v=1613806802261"
      },
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/fnrkeEj5AFayo3v5V4SPkw/FeBhgoXMQ3A?v=1613806802261"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/cNt1tKgGZ_1yp3CCYactvg/FeBhgoXMQ3A?v=1613806802261"
      }
    ],
    highres: [
      {
        fileSize: 9379775,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 3380,
        height: 5079
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_EAH0",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 9379775,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 5079,
        exampleWidth: 3380
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "portrait",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "FeBhgoXMQ3A",
    originalRefPtr: "tabfd4cb",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 48,
    credit: "Scandinav",
    previewLocation:
      "https://preview.sdl.no/v1/no/k_Nh_JigIG6iYbxvgsS5HA/3um5ZH8-E0s?v=1613793738945",
    lightboxSupport: true,
    byline: "Therese Winberg/Scandinav",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2015-12-09 03:35:36",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/oBW0XwcLN8bcp9enKByT9w/3um5ZH8-E0s?v=1613793738945"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/k_Nh_JigIG6iYbxvgsS5HA/3um5ZH8-E0s?v=1613793738945"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/XoxshAJ-Dk1GqSxIUPDUuw/3um5ZH8-E0s?v=1613793738945"
      }
    ],
    highres: [
      {
        fileSize: 16289672,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5079,
        height: 3386
      }
    ],
    distributorName: "Scandinav",
    billingImageId: "Scandinav_7MB0",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 16289672,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3386,
        exampleWidth: 5079
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 475,
    businessArea: "editorial",
    refPtr: "3um5ZH8-E0s",
    originalRefPtr: "tabfc42e",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  },
  {
    mainGroup: "creative",
    offset: 49,
    credit: "Maskot",
    headline: "High angle view of determined businessman working late in office",
    caption: "High angle view of determined businessman working late in office",
    previewLocation:
      "https://preview.sdl.no/v1/no/0CSaEvYwhvzVZaYmNEO73g/PZjRaXFrg2U?v=1622710948503",
    lightboxSupport: true,
    byline: "Maskot",
    inLightbox: false,
    outsideAbb: false,
    insideSubscription: false,
    restrictions: false,
    ftpSupport: true,
    vgSmpSupport: false,
    downloadable: true,
    confirmDownload: true,
    archivedTime: "2020-06-24 16:28:39",
    bigPreviewSupport: true,
    thumbHeight: 0,
    thumbWidth: 0,
    previewList: [
      {
        type: "preview_big",
        url: "https://preview.sdl.no/v1/no/yJdQ63LBTTxt7LPgMiCF1A/PZjRaXFrg2U?v=1622710948503"
      },
      {
        type: "preview",
        url: "https://preview.sdl.no/v1/no/0CSaEvYwhvzVZaYmNEO73g/PZjRaXFrg2U?v=1622710948503"
      },
      {
        type: "thumbnail_big",
        url: "https://preview.sdl.no/v1/no/yQyD261CtFHsKsqqwq9MDg/PZjRaXFrg2U?v=1622710948503"
      }
    ],
    highres: [
      {
        fileSize: 8850685,
        fileType: "HIGHRES_1",
        mimeType: "image/jpeg",
        width: 5700,
        height: 3800
      }
    ],
    distributorName: "Maskot",
    billingImageId: "MA79721.jpg",
    mediaPrices: [
      {
        name: "HIGHRES_1",
        price: "1340",
        downloadable: true,
        size: 8850685,
        mimeType: "jpeg",
        exampleDpi: 300,
        exampleHeight: 3800,
        exampleWidth: 5700
      }
    ],
    subjectTerms: [],
    editable: false,
    orientation: "landscape",
    distributorId: 57,
    businessArea: "editorial",
    refPtr: "PZjRaXFrg2U",
    sdlOwner: "NTB",
    base: "UNKNOWN",
    isPlaybackSupport: false
  }
];

export default assets;
