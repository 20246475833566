import { LS_KEY_PREFERRED_ALBUM_VIEW_MODE } from "../../constants/localStorage";
import {
  SET_VIEW_MODE,
  SET_ASSETS,
  FETCHING_ALBUM_LIST,
  SET_ALBUM_LIST,
  SET_ALBUM_DETAILS,
  FETCHING_ALBUM,
  SET_ALBUM_MESSAGE,
  SET_ALBUM_SEARCH_QUERY,
  SET_ALBUM_OFFSET,
  SET_HAS_MORE_ALBUMS,
  SET_ALBUM_SEARCH_TOTAL_COUNT
} from "../actions/album";

const albumReducer = (
  state = {
    viewMode: localStorage.getItem(LS_KEY_PREFERRED_ALBUM_VIEW_MODE) || "GRID",
    assets: [],
    albumListLoading: false,
    albumListLoaded: false,
    albumList: [],
    albumDetails: {},
    message: "",
    fetchingAlbum: false,
    albumQuery: "",
    hasMore: true,
    offset: 0,
    totalCount: 0
  },
  action
) => {
  switch (action.type) {
    case SET_VIEW_MODE: {
      localStorage.setItem(LS_KEY_PREFERRED_ALBUM_VIEW_MODE, action.value);
      return {
        ...state,
        viewMode: action.value
      };
    }

    case SET_ASSETS: {
      return {
        ...state,
        assets: action.value
      };
    }

    case FETCHING_ALBUM_LIST: {
      return {
        ...state,
        albumListLoading: true
      };
    }

    case SET_ALBUM_LIST: {
      return {
        ...state,
        albumList: action.value,
        albumListLoading: false,
        albumListLoaded: true
      };
    }

    case SET_HAS_MORE_ALBUMS: {
      return { ...state, hasMore: action.hasMore };
    }

    case FETCHING_ALBUM: {
      return {
        ...state,
        fetchingAlbum: action.value
      };
    }

    case SET_ALBUM_DETAILS: {
      return { ...state, albumDetails: action.value, fetchingAlbum: false };
    }

    case SET_ALBUM_MESSAGE: {
      return { ...state, message: action.value, albumListLoading: false, albumListLoaded: true };
    }

    case SET_ALBUM_SEARCH_QUERY: {
      return { ...state, albumQuery: action.value };
    }

    case SET_ALBUM_OFFSET: {
      return { ...state, offset: action.offset };
    }

    case SET_ALBUM_SEARCH_TOTAL_COUNT: {
      return { ...state, totalCount: action.count };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default albumReducer;
