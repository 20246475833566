import React from "react";
import { createPortal } from "react-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "../../assets/scss/components/system/toast.module.scss";
import { ReactComponent as TimesIcon } from "../../assets/icons/times.svg";

function Toast() {
  const CloseButton = ({ closeToast }) => (
    <div onClick={closeToast} className={style["toast__close-button"]}>
      <TimesIcon />
    </div>
  );

  const ToastWrapper = () => (
    <ToastContainer
      closeOnClick={false}
      autoClose={5000}
      hideProgressBar
      position={toast.POSITION.BOTTOM_LEFT}
      closeButton={<CloseButton />}
    />
  );

  return createPortal(<ToastWrapper />, document.body);
}

export default Toast;
