import { SET_PAGE_TITLE } from "../actions/global";

const DEFAULT_PAGE_TITLE = "NTB Bilder og video"

const userReducer = (
  state = {
    pageTitle: DEFAULT_PAGE_TITLE,
    defaultPageTitle: DEFAULT_PAGE_TITLE,
    pageTitleTemplate: "s% | NTB Bilder og video",
  },
  action
) => {
  switch (action.type) {
    case SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.value ? state.pageTitleTemplate.replace("s%", action.value) : DEFAULT_PAGE_TITLE
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default userReducer;
