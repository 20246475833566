import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

function GoogleAnalytics() {
  const location = useLocation();
  const GA = useGoogleAnalytics()

  useEffect(() => {
    GA.trackPageView(location.pathname)
  }, [location, GA]);

  return null;
}

export default GoogleAnalytics;
