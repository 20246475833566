import ScanpixApi from "./index";

export default class MyPageAction extends ScanpixApi {
  constructor() {
    super();
    super._setAction("mypage.action");
  }

  async getProfile() {
    super._setQuery({ json: "" });

    return super._fetch({
      ky: {
        redirect: "manual",
        redirectCallback: () => {
          // Will redirect you to `/login.action?logout=&inactive=true` if this request resolts in
          // a 301/302 response
          super._setAction("login.action");
          super._setQuery({ logout: "", inactive: true });
          super._changeLocation();
        }
      }
    });
  }

  async activateAccount(username, recaptchaCode) {
    const formData = new FormData();
    formData.set("sendActivationLink", "");
    formData.set("sid", "29");
    formData.set("user.username", username);
    formData.set("g-recaptcha-response", recaptchaCode);
    formData.set("sendPasswd", "Send aktiveringslink");

    return super._post(formData);
  }
}
