import ReactGA from "react-ga4";
import { useMemo } from "react";
import env from "../helpers/env";

class GoogleAnalyticsTracker {
  context = new Map();

  constructor() {
    this.context.set("company_name", null);
    if (env === "development") return;
    ReactGA.initialize("G-93LN4WRSXD");
  }

  /**
   * Send page view
   * @param {Pathname} path
   */
  trackPageView(path) {
    if (env === "development") return;

    ReactGA.send({ hitType: "pageview", page: path });
  }

  /**
   * Send event
   * @param {string} eventName
   * @param {object?} params
   */
  event(eventName, params = {}) {
    if (env === "development") return;

    const defaultParams = this.getDefaultParams();
    ReactGA.event(eventName, {
      ...defaultParams,
      ...params
    });
  }

  getDefaultParams() {
    return {
      company_name: this.context.get("company_name")
    };
  }
}

export const GA = new GoogleAnalyticsTracker();

export default function useGoogleAnalytics() {
  return useMemo(() => GA, []);
}
