import { SET_SORT_ORDER, TOOGLE_FILTER_MENU } from "../actions/album-filter";

const DEFAULT_SORT_FIELD = "isPinned";
const DEFAULT_SORT_ORDER = "desc";

const albumFilterReducer = (
  state = {
    sortField: DEFAULT_SORT_FIELD,
    sortOrder: DEFAULT_SORT_ORDER,
    isAlbumFilterOpen: false
  },
  action
) => {
  switch (action.type) {
    case TOOGLE_FILTER_MENU: {
      return { ...state, isAlbumFilterOpen: !state.isAlbumFilterOpen };
    }

    case SET_SORT_ORDER: {
      const { sortField: currentSortField, sortOrder: currentSortOrder } = state;
      const { sortField: newSortField, sortOrder: newSortOrder } = action;

      // Remove the sort if clicked twice
      return currentSortField === newSortField && currentSortOrder === newSortOrder
        ? { ...state, sortField: DEFAULT_SORT_FIELD, sortOrder: DEFAULT_SORT_ORDER }
        : { ...state, sortField: newSortField, sortOrder: newSortOrder };
    }

    default:
      return { ...state };
  }
};

export default albumFilterReducer;
