import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import classNames from "classnames";
import style from "../../assets/scss/components/form/text-input.module.scss";

function TextInput({ wrapperClassName, type = "text", value = "", onValueChange, onClick, label }) {
  const uniqueId = shortid.generate();
  const [empty, setEmpty] = useState(true);

  useEffect(() => {
    setEmpty(!value);
  }, [value]);

  return (
    <div className={classNames(style["text-input"], wrapperClassName)}>
      <input
        className={classNames(style["text-input__input"], {
          [style["text-input__input--empty"]]: empty
        })}
        type={type}
        id={`text-input-${uniqueId}`}
        value={value}
        onClick={onClick}
        onChange={e => onValueChange(e.target.value)}
      />
      <label className={style["text-input__label"]} htmlFor={`text-input-${uniqueId}`}>
        {label}
      </label>
    </div>
  );
}

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  label: PropTypes.string.isRequired
};

export default TextInput;
