/*
 * For search validation
 * */
class LuceneValidator {
  static removeEscapes(query) {
    return query.replace(/\\./g, "");
  }

  static checkAllowedCharacters(query) {
    if (/[^\x20-\xFF]+$/.test(query)) {
      return (
        // "The allowed characters are a-z A-Z 0-9.  _ + - : () \" & * ? | ! {} [ ] ^ ~ \\ @ = # % $ ' /."
        "De tillatte tegnene er a-z A-Z 0-9.  _ + - : () \" & * ? | ! {} [ ] ^ ~ \\ @ = # % $ ' /."
      );
    }
  }

  static checkAsterisk(query) {
    if (/^[*]*$|[\s]\*|^\*[^\s]/.test(query)) {
      // return "The wildcard (*) character must be preceded by at least one alphabet or number.";
      return "Asterisk (*) tegnet må etterfølges av minst en bokstav eller nummer.";
    }
  }

  static checkAmpersands(query) {
    // NB: doesn't handle term1 && term2 && term3 in Firebird 0.7
    var matches = query.match(/[&]{2}/);
    if (matches && matches.length > 0) {
      matches = query.match(/^([\x20-\xFF]+( && )?[\x20-\xFF]+[ ]*)+$/); // note missing & in pattern
      if (!matches) {
        // return "Queries containing the special characters && must be in the form; term1 && term2.";
        return "Søk som inneholder spesialtegnene (&&) må være i formatet; term1 && term2";
      }
    }
  }

  static checkCaret(query) {
    if (/[^\\]\^([^\s]*[^0-9.]+)|[^\\]\^$/.test(query)) {
      // return "The caret (^) character must be preceded by alphanumeric characters and followed by numbers.";
      return "Innsettingstegn (^) må innledes med alfanumeriske tegn og etterfølges av tall.";
    }
  }

  static checkTilde(query) {
    if (/[^\\]~[^\s]*[^0-9\s]+/.test(query)) {
      // return "The tilde (~) character must be preceded by alphanumeric characters and followed by numbers.";
      return "Tilde-tegnet (~) må innledes med alfanumeriske tegn og etterfølges av tall.";
    }
  }

  static checkExclamationMark(query) {
    // NB: doesn't handle term1 ! term2 ! term3 or term1 !term2
    if (!/^[^!]*$|^([\x20-\xFF]+( ! )?[\x20-\xFF]+[ ]*)+$/.test(query)) {
      // return "Queries containing the special character ! must be in the form; term1 ! term2.";
      return "Søk som inneholder spesialtegnet (!) må være i formatet; term1 ! term2.";
    }
  }

  static checkQuestionMark(query) {
    if (/^(\?)|([^\x20-\xFF]\?+)/.test(query)) {
      // return "The question mark (?) character must be preceded by at least one alphabet or number.";
      return "Spørsmåltegn (?) må etterfølges av minst en bokstav eller nummer.";
    }
  }

  static checkParentheses(query) {
    let matchLeft = query.match(/[(]/g),
      matchRight = query.match(/[)]/g),
      countLeft = matchLeft ? matchLeft.length : 0,
      countRight = matchRight ? matchRight.length : 0;

    if (!matchLeft && !matchRight) {
      return;
    }

    if ((matchLeft && !matchRight) || (matchRight && !matchLeft)) {
      // return "Parentheses must be closed.";
      return "Parenteser må være lukket.";
    }

    if ((countLeft + countRight) % 2 > 0 || countLeft !== countRight) {
      // return "Parentheses must be closed.";
      return "Parenteser må være lukket.";
    }

    if (/\(\)/.test(query)) {
      // return "Parentheses must contain at least one character.";
      return "Parenteser må inneholde minst en tegn";
    }
  }

  static checkPlusMinus(query) {
    if (!/^[^\n+-]*$|^([+-]?[\x20-\xFF]+[ ]?)+$/.test(query)) {
      // return "'+' and '-' modifiers must be followed by at least one alphabet or number.";
      return "'+' og '-' modifikatorer må følges av minst en bokstav eller et tall.";
    }
  }

  static checkANDORNOT(query) {
    if (!/AND|OR|NOT/.test(query)) {
      return;
    }

    if (!/^([\x20-\xFF]*((AND )|(OR )|(AND NOT )|(NOT ))?[\x20-\xFF]+[ ]*)+$/.test(query)) {
      // return "Queries containing AND/OR/NOT must be in the form; term1 AND|OR|NOT|AND NOT term2";
      return "Søk som inneholder AND/OR/NOT må være i formatet: term1 AND|OR|NOT|AND NOT term2";
    }

    if (/^((AND )|(OR )|(AND NOT )|(NOT ))|((\bAND)|(\bOR)|(AND NOT )|(\bNOT))[ ]*$/.test(query)) {
      // return "Queries containing AND/OR/NOT must be in the form; term1 AND|OR|NOT|AND NOT term2";
      return "Søk som inneholder AND/OR/NOT må være i formatet: term1 AND|OR|NOT|AND NOT term2";
    }
  }

  static checkQuotes(query) {
    let matches = query.match(/"/g),
      matchCount;

    if (!matches) {
      return;
    }

    matchCount = matches.length;

    if (matchCount % 2 !== 0) {
      // return 'Please close all quote (") marks.';
      return 'Lukk alle anførselstegn (").';
    }

    if (/""/.test(query)) {
      // return "Quotes must contain at least one character.";
      return "Tekst i anførselstegn må inneholde minst ett tegn.";
    }
  }

  static checkColon(query) {
    if (/[^\\\s]:[\s]|[^\\\s]:$|[\s][^\\]?:|^[^\\\s]?:/.test(query)) {
      // return "Feltdeklarasjoner (:) må innledes med minst en bokstav eller nummer og etterfulgt av minst en bokstav eller et tall.";
      return "Field declarations (colon) must be preceded by at least one alphabet or number and followed by at least one alphabet or number.";
    }
  }

  static validate(query) {
    if (!query || query === "") {
      return null;
    }

    query = this.removeEscapes(query);

    let errorMsg,
      tests = [
        //this.checkAllowedCharacters, // Disabled as it has no function
        // this.checkAsterisk, // Disabled as asterisk is supported
        this.checkAmpersands,
        this.checkCaret,
        this.checkTilde,
        this.checkExclamationMark,
        this.checkQuestionMark,
        this.checkParentheses,
        this.checkPlusMinus,
        this.checkANDORNOT,
        this.checkQuotes
        //this.checkColon // This method is prevented in backend
      ];

    for (let i = tests.length - 1; i >= 0; i--) {
      errorMsg = tests[i](query);
      if (errorMsg) {
        return errorMsg;
      }
    }

    return null;
  }
}

export default LuceneValidator;
