import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

function ShowOnScreenSize({ from = null, to = null, children }) {
  const [shouldBeVisible, setShouldBeVisible] = useState(true);

  const windowResized = () => {
    const innerWidth = window.innerWidth;
    let shouldBeVisibleCheck = true;

    if (from && from > innerWidth) {
      shouldBeVisibleCheck = false;
    }

    if (to && to < innerWidth) {
      shouldBeVisibleCheck = false;
    }

    if (shouldBeVisible === shouldBeVisibleCheck) {
      return;
    }

    setShouldBeVisible(shouldBeVisibleCheck);
  };

  useEffect(() => {
    windowResized();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("resize", _.throttle(windowResized, 100));
    return () => window.removeEventListener("resize", _.throttle(windowResized, 100));
  });

  return shouldBeVisible ? children : null;
}

ShowOnScreenSize.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number
};

export default ShowOnScreenSize;
