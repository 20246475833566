import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import style from "../../assets/scss/components/ui-element/modal.module.scss";
import { ReactComponent as TimesIcon } from "../../assets/icons/times.svg";

function Modal({ open, onClose, children, wrapperClassName, boxClassName, showCrossIcon = true }) {
  useEffect(() => {
    const closeModal = e => {
      if (!_.isFunction(onClose)) return;

      if (e.key === "Escape") {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("keyup", closeModal);
    }

    return () => document.removeEventListener("keyup", closeModal);
  }, [open, onClose]);

  if (!open) {
    document.body.style.overflow = null;
    return null;
  }

  const preventReactRouterInheritance = e => e.stopPropagation();

  document.body.style.overflow = "hidden";

  return ReactDOM.createPortal(
    <div
      className={classNames(style["modal"], wrapperClassName)}
      onClick={preventReactRouterInheritance}
    >
      <div className={classNames(style["modal__box"], boxClassName)}>
        {showCrossIcon ? (
          <TimesIcon className={style["modal__box__close-button"]} onClick={onClose} />
        ) : null}
        {children}
      </div>
    </div>,
    document.getElementById("root")
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

export default Modal;
