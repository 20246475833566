import shortId from "shortid";
import { toast } from "react-toastify";
import { captureException } from "@sentry/react";

import SearchAction from "../../helpers/api/search-action";

import dummyAssets from "../../dummy-data/assets";

import { setOffset, setSubscription } from "./search-filter";
import { LS_KEY_SEARCH_FILTER_SUBSCRIPTION } from "../../constants/localStorage";
import { GA } from "../../hooks/useGoogleAnalytics";
import { isEmpty, mapKeys, omit, snakeCase } from "lodash";
import { setExpandClick, setIsBucketLoading } from "./aggregations";

//Action Types
export const FETCHING_ASSETS = "FETCHING_ASSETS";
export const SET_LATEST_FETCH_ID = "SET_LATEST_FETCH_ID";
export const ASSETS_RECEIVED = "ASSETS_RECEIVED";
export const SET_HUMAN_RESULT = "SET_HUMAN_RESULT";
export const SET_AGGREGATED_FILTERS = "SET_AGGREGATED_FILTERS";
export const SET_SEARCH_INSTANCE = "SET_SEARCH_INSTANCE";
export const SET_SEARCH_VIEW_CONFIG = "SET_SEARCH_VIEW_CONFIG";
export const FETCHING_RESULTS_FROM_OTHER_BASES = "ASSET_SEARCH_FETCHING_RESULTS_FROM_OTHER_BASES";
export const RECEIVED_RESULTS_FROM_OTHER_BASES = "ASSET_SEARCH_RECEIVED_RESULTS_FROM_OTHER_BASES";
export const SET_OTHER_MAIN_GROUPS_WITH_RESULTS = "ASSET_SET_OTHER_MAIN_GROUPS_WITH_RESULTS";

const makeCurrentSearch = searchFilter => {
  return new SearchAction()
    .searchString(searchFilter.searchString)
    .mainGroup(searchFilter.mainGroup)
    .sortOrder(searchFilter.sortOrder)
    .offset(searchFilter.offset)
    .searchAfter(searchFilter.searchAfter)
    .limit(searchFilter.limit)
    .photographer(searchFilter.photographer)
    .distributorName(searchFilter.distributorName)
    .credit(searchFilter.credit)
    .keywords(searchFilter.keywords)
    .subjectTerms(searchFilter.subjectTerms)
    .editorialTopics(searchFilter.editorialTopics)
    .subscription(searchFilter.subscription)
    .timeLimit(searchFilter.timeLimit)
    .createdMin(searchFilter.createdMin)
    .createdMax(searchFilter.createdMax)
    .events(searchFilter.events)
    .persons(searchFilter.persons)
    .properNames(searchFilter.properNames)
    .places(searchFilter.places)
    .city(searchFilter.city)
    .country(searchFilter.country)
    .pictureOrientation(searchFilter.pictureOrientation)
    .colors(searchFilter.colors)
    .stockColor(searchFilter.stockColor)
    .blackAndWhite(searchFilter.blackAndWhite)
    .clearEdge(searchFilter.clearEdge)
    .creativeTopics(searchFilter.creativeTopics)
    .creativePeople(searchFilter.creativePeople)
    .stockImageType(searchFilter.stockImageType)
    .videoEdit(searchFilter.videoEdit)
    .videoTopics(searchFilter.videoTopics)
    .vgTopics(searchFilter.vgTopics)
    .assignmentNumbers(searchFilter.assignmentNumbers)
    .refPtrs(searchFilter.refPtrs);
};

export const fetchAggregations = () => {
  return async (dispatch, getState) => {
    const aggregations = getState().aggregations;
    const searchFilter = getState().searchFilter;
    const search = makeCurrentSearch(searchFilter);
    const { loadedFilterGroup, loadedAggregationGroup } = aggregations;

    if (isEmpty(loadedAggregationGroup) && isEmpty(loadedFilterGroup)) {
      dispatch(setAggregatedFilters([]));
      return;
    }

    dispatch(setIsBucketLoading(true));

    search
      .giveAggregations(true)
      .returnAllAggregation(false)
      .aggregationGroup(loadedAggregationGroup)
      .filterGroup(loadedFilterGroup)
      .useUrl(null)
      .limit(0)
      .searchAfter([])
      .fetch()
      .then(res => {
        dispatch(setAggregatedFilters(res.aggregations));
        dispatch(setIsBucketLoading(false));
        dispatch(setExpandClick(undefined));
      })
      .catch(error => {
        dispatch(setIsBucketLoading(false));
        dispatch(setExpandClick(undefined));
        toast.error("En feil oppstod under henting av søkefilter – prøv igjen litt senere");
        captureException(error);
      });
  };
};

//Action Creators
export const fetchAssets = (force = false, append = false, prepend = false) => {
  return async (dispatch, getState) => {
    const assetSearch = getState().assetSearch;
    const searchFilter = getState().searchFilter;
    const user = getState().user;

    const currentRequestRequiresAuth = Boolean(
      !user.authenticated &&
        searchFilter.possibleMainGroups.find(
          mainGroup =>
            mainGroup.mainGroup === searchFilter.mainGroup && mainGroup.requiresAuthentication
        )
    );

    let thisSearchUrl = "";
    const search = makeCurrentSearch(searchFilter);
    search.useUrl(url => {
      thisSearchUrl = url;
    });

    if (assetSearch.lastSearchUrl === thisSearchUrl && !force) {
      // Prevent unnecessary additional search
      return;
    }

    dispatch(fetchingAssets(thisSearchUrl, append, prepend));

    if (currentRequestRequiresAuth) {
      search.limit(0);
      dispatch(assetsReceived({ images: dummyAssets, numResults: 1 }, append, prepend));
    }

    const fetchId = shortId();
    if (!append && !prepend) {
      dispatch(setLastFetchId(fetchId));

      const viewConfig = assetSearch.searchViewConfig;
      if (
        viewConfig.defaultSubscriptionSearch &&
        !window.localStorage.getItem(LS_KEY_SEARCH_FILTER_SUBSCRIPTION)
      ) {
        // set default subscription if nothing is stored in localStorage
        search.subscription("subscription");

        // update lastSearchUrl to prevent duplicate searches
        search.useUrl(url => {
          thisSearchUrl = url;
        });

        dispatch(fetchingAssets(thisSearchUrl, append, prepend));

        // commit the new subscription setting
        dispatch(setSubscription("subscription"));
      }
    }

    if (prepend || force) {
      // we don't want a offset because we are prepending the results
      search.offset(0).searchAfter([]);
    }

    dispatch(
      setSearchInstance(Object.assign(Object.create(Object.getPrototypeOf(search)), search))
    );

    if (searchFilter.searchAfter?.length <= 0 && searchFilter.offset === 0 && !append && !prepend) {
      GA.event("asset_search", {
        ...mapKeys(
          omit(searchFilter, [
            "limit",
            "searchAfter",
            "offset",
            "menuOpen",
            "possibleMainGroups",
            "defaultFilters",
            "tempSearchString"
          ]),
          (value, key) => `search_filter_${snakeCase(key)}`
        )
      });
    }

    search
      .fetch({ returnFullResponse: true, ky: { fetchId } })
      .then(async res => {
        let data = await res.json();

        dispatch(setLastFetchId(fetchId));

        if (!append && !prepend && getState().assetSearch.fetchId !== res.options.fetchId) {
          return;
        }

        // Sometime while scrolling data from microstock mixup with other groups.
        // To prevent that we need to check if current mainGroup is same as the
        // previously requested maingroup.
        if (
          getState().searchFilter.mainGroup !== searchFilter.mainGroup ||
          getState().searchFilter.searchString !== searchFilter.searchString
        ) {
          return;
        }

        if (prepend) {
          data.images = data.images.filter(newAsset => {
            return !assetSearch.assets.some(asset => asset.refPtr === newAsset.refPtr);
          });

          // If the result contains any search after value we don't need to calculate offset
          const searchAfter = data.searchAfter;
          if (!searchAfter || searchAfter.length <= 0) {
            //set offset
            if (data.images.length) {
              const newOffset = searchFilter.offset + data.images.length;
              dispatch(setOffset(newOffset));
            }
          }
        }

        if (!currentRequestRequiresAuth) {
          dispatch(assetsReceived(data, append, prepend));
        }

        if (!append && !prepend) {
          dispatch(setHumanResult(searchFilter.mainGroup, data.humanNumResults));

          if (!currentRequestRequiresAuth || !!user.authenticated) {
            dispatch(fetchAggregations());
          }

          const currentMainGroupObject = searchFilter.possibleMainGroups.find(
            possibleMainGroup => possibleMainGroup.mainGroup === searchFilter.mainGroup
          );

          const otherMainGroupsToGetHumanResultsFrom = searchFilter.possibleMainGroups.filter(
            possibleMainGroup => {
              return (
                possibleMainGroup.mainGroup !== searchFilter.mainGroup &&
                currentMainGroupObject.type === possibleMainGroup.type &&
                possibleMainGroup.mainGroup !== "panther" &&
                !possibleMainGroup.external
              );
            }
          );

          // add mainGroups with `alsoCheck` if the search result is empty on the active `mainGroup`
          if (data.numResults === 0) {
            const alsoCheckMainGroupObjects = searchFilter.possibleMainGroups.filter(
              possibleMainGroup =>
                possibleMainGroup.mainGroup !== searchFilter.mainGroup &&
                currentMainGroupObject.type !== possibleMainGroup.type &&
                possibleMainGroup.alsoCheck
            );

            for (const alsoCheckMainGroupObject of alsoCheckMainGroupObjects) {
              otherMainGroupsToGetHumanResultsFrom.push(alsoCheckMainGroupObject);
            }

            dispatch(setOtherMainGroupsWithResults([]));
            dispatch(fetchingResultsFromOtherBases());
          }

          const fetchingResultsFromOtherBasesPromises = [];
          for (const mainGroupObject of otherMainGroupsToGetHumanResultsFrom) {
            fetchingResultsFromOtherBasesPromises.push(
              new Promise(async resolve => {
                const response = await search
                  .mainGroup(mainGroupObject.mainGroup)
                  .limit(0)
                  .searchAfter([])
                  .giveAggregations(false)
                  .fetch();

                assetSearch.humanResults[mainGroupObject.mainGroup] = response.humanNumResults;
                dispatch(setHumanResult(mainGroupObject.mainGroup, response.humanNumResults));

                resolve();
              })
            );
          }

          try {
            await Promise.all(fetchingResultsFromOtherBasesPromises);

            if (data.numResults === 0) {
              const otherMainGroupsWithResults = otherMainGroupsToGetHumanResultsFrom.filter(
                possibleMainGroup => {
                  return (
                    assetSearch.humanResults[possibleMainGroup.mainGroup] &&
                    assetSearch.humanResults[possibleMainGroup.mainGroup] !== "0" &&
                    possibleMainGroup.alsoCheck
                  );
                }
              );

              dispatch(setOtherMainGroupsWithResults(otherMainGroupsWithResults));
            }
          } catch (e) {}

          dispatch(receivedResultsFromOtherBases());
        }
      })
      .catch(error => {
        dispatch(assetsReceived({ images: [] }));
        toast.error("En feil oppstod under søket – prøv igjen litt senere");
        captureException(error);
      });
  };
};

const fetchingAssets = (url, append, prepend) => ({
  type: FETCHING_ASSETS,
  url,
  append,
  prepend
});

const setLastFetchId = value => ({
  type: SET_LATEST_FETCH_ID,
  value
});

const fetchingResultsFromOtherBases = () => ({
  type: FETCHING_RESULTS_FROM_OTHER_BASES
});

const receivedResultsFromOtherBases = () => ({
  type: RECEIVED_RESULTS_FROM_OTHER_BASES
});

const setOtherMainGroupsWithResults = value => ({
  type: SET_OTHER_MAIN_GROUPS_WITH_RESULTS,
  value
});

const assetsReceived = (value, append, prepend) => ({
  type: ASSETS_RECEIVED,
  value,
  append,
  prepend
});

const setHumanResult = (mainGroup, value) => ({
  type: SET_HUMAN_RESULT,
  mainGroup,
  value
});

const setAggregatedFilters = value => ({
  type: SET_AGGREGATED_FILTERS,
  value
});

const setSearchInstance = value => ({
  type: SET_SEARCH_INSTANCE,
  value
});

export const setSearchViewConfig = value => ({
  type: SET_SEARCH_VIEW_CONFIG,
  value
});
