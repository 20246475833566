import { LS_KEY_PREFERRED_THEME } from "../../constants/localStorage";
import { SET_PREFERRED_THEME } from "../actions/settings";

const settingsReducer = (
  state = {
    preferredTheme: localStorage.getItem(LS_KEY_PREFERRED_THEME) || "default"
  },
  action
) => {
  switch (action.type) {
    case SET_PREFERRED_THEME: {
      localStorage.setItem(LS_KEY_PREFERRED_THEME, action.value);
      return {
        ...state,
        preferredTheme: action.value
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default settingsReducer;
