import { toast } from "react-toastify";
import { ALBUM_PER_FETCH_SIZE } from "../../store/actions/album";
import ScanpixApi from "./index";
const APP_NAME_QUERY = "'app':'imagePortal'";
export default class LightboxAction extends ScanpixApi {
  constructor() {
    super();
    super._setAction("lightbox.action");
  }

  listLightboxes(
    searchQuery = "",
    sortField,
    sortOrder,
    offset = 0,
    limit = ALBUM_PER_FETCH_SIZE,
    withCoverPhoto = true
  ) {
    searchQuery = searchQuery === "" ? APP_NAME_QUERY : `${APP_NAME_QUERY} and ${searchQuery}`;
    super._setQuery({
      listLightboxAjax: "",
      "search.query": searchQuery,
      "search.sortOrder": sortOrder,
      "search.sort": sortField,
      "search.offset": offset,
      "search.limit": limit,
      "search.withCoverPhoto": withCoverPhoto
    });
    return super._fetch();
  }

  listLightboxByIds(lightboxIds = []) {
    const query = { listLightboxByIds: "" };
    lightboxIds.forEach((id, index) => (query[`lightboxIds[${index}]`] = id));

    super._setQuery(query);

    return super._fetch();
  }

  async fetchLightboxById(lightboxId) {
    super._setQuery({ fetchLightboxById: "", lightboxId });
    return super._fetch();
  }

  async getCurrentLightbox(lightboxId) {
    super._setQuery({ list: "", lightboxId });
    return this._fetch();
  }

  async setCurrentLightbox(name = "") {
    super._setQuery({
      currentLightboxName: decodeURIComponent(name),
      changeLightboxAjax: ""
    });

    return super._fetch();
  }

  async renameCurrentLightbox(newName = "", lightboxId) {
    const formData = new FormData();
    formData.set("newLightboxName", newName);
    formData.set("changeCurrentLightboxName", "");
    formData.set("lightboxId", lightboxId);

    return super._post(formData);
  }

  async deleteCurrentLightbox(lightboxId) {
    super._setQuery({ deleteCurrentLightboxFull: "", lightboxId });
    return super._fetch();
  }

  async deleteLightBoxByIds(lightboxIds, callbackFn) {
    const formData = new FormData();
    lightboxIds.forEach(id => formData.append("lightboxIds", id));
    formData.set("deleteLightBoxByIds", "");

    const response = super._post(formData);
    response.then(message => {
      toast(message, { theme: "light", bodyStyle: { color: "black" } });
      if (callbackFn) {
        callbackFn();
      }
    });
  }

  downloadPreviews(lightboxId) {
    super._setQuery({ downloadPreviews: "", lightboxId });
    super._changeLocation();
  }

  async canDownloadHighResWithoutComment(includeAssetsOutsideSubscription = true, lightboxId) {
    super._setQuery({
      checkComments: "",
      transferOutsideAbbToo: includeAssetsOutsideSubscription,
      lightboxId
    });

    const res = await super._post();
    const canContinue = typeof res === "string" && res === "OK_CONTINUE";

    if (canContinue) return true;
    return res;
  }

  async postComment(formData = new FormData()) {
    const res = await super._post(formData);

    if (res !== "OK_CONTINUE") {
      throw Error(res);
    }

    return res;
  }

  async sendCurrentLighboxViaEmail(formData = new FormData()) {
    return await super._post(formData);
  }

  async isFTPZIPPunches(lightboxId) {
    super._setQuery({ lightboxId, isFTPZIPPunches: "" });
    let res = await super._fetch();
    res = res.split("_");

    return {
      ftpAllAvailable: JSON.parse(res[0]),
      zipAllAvailable: JSON.parse(res[1]),
      enoughPunchesLeft: !JSON.parse(res[2])
    };
  }

  async downloadWithComment(formData = new FormData(), downloadType) {
    if (downloadType === "FTP") {
      return await super._post(formData, { returnFullResponse: true });
    } else {
      const form = document.createElement("form");
      form.method = "post";
      form.action = super._getFetchUrl();

      for (const pair of formData.entries()) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = pair[0];
        hiddenField.value = pair[1];

        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    }
  }

  downloadZip(includeAssetsOutsideSubscription = true, lightboxId) {
    super._setQuery({
      lightboxId,
      zipCurrentLightbox: "",
      transferOutsideAbbToo: includeAssetsOutsideSubscription
    });
    return super._changeLocation();
  }

  sendToFTP(includeAssetsOutsideSubscription = true, lightboxId) {
    super._setQuery({
      lightboxId,
      ftpCurrentLightbox: "",
      transferOutsideAbbToo: includeAssetsOutsideSubscription
    });
    return super._fetch();
  }

  addToLightbox(refPtr = "", mainGroup = "", lightboxId) {
    const formData = new FormData();
    formData.set("addToLightboxAjax", "");
    formData.set("currentRefPtr", refPtr);
    formData.set("base", mainGroup);
    formData.set("lightboxId", lightboxId);

    return super._post(formData);
  }

  _createFormdataFromAsset(assets = []) {
    const formData = new FormData();
    assets.forEach((asset, index) => {
      const { refPtr, mainGroup } = asset;
      formData.append(`mediaKeys[${index}].refPtr`, refPtr);
      formData.append(`mediaKeys[${index}].base`, mainGroup);
    });

    return formData;
  }

  addToLightboxMulti(assets = [], lightboxId) {
    const formData = this._createFormdataFromAsset(assets);
    formData.set("addToLightboxMulti", "");
    formData.set("lightboxId", lightboxId);

    return super._post(formData);
  }

  removeFromLightboxMulti(assets = [], lightboxId) {
    const formData = this._createFormdataFromAsset(assets);
    formData.set("removeFromLightboxMulti", "");
    formData.set("lightboxId", lightboxId);

    return super._post(formData);
  }

  createLightbox(name) {
    const formData = new FormData();
    formData.set("newLightboxName", name);
    formData.set("createNewLightboxFull", "");

    return super._post(formData);
  }

  moveItems(items, newLightbox, lightboxId) {
    const formData = new FormData();

    items.forEach(item => formData.append("selectedItems", item.refPtr));

    formData.set("orderBy", "ID_DESC");
    formData.set("moveTo", newLightbox);
    formData.set("moveToOther", "");
    formData.set("lightboxId", lightboxId);

    return super._post(formData);
  }

  copyItems(items, newLightbox, lightboxId) {
    const formData = new FormData();

    items.forEach(item => formData.append("selectedItems", item.refPtr));

    formData.set("orderBy", "ID_DESC");
    formData.set("moveTo", newLightbox);
    formData.set("copyToOther", "");
    formData.set("lightboxId", lightboxId);

    return super._post(formData);
  }

  async pinAlbum(lightboxId, callbackFn) {
    const formData = new FormData();
    formData.set("lightboxId", lightboxId);
    formData.set("pinAlbum", "");

    await super._post(formData);
    if (callbackFn) {
      callbackFn();
    }
  }

  async unpinAlbum(lightboxId, callbackFn) {
    super._setQuery({ lightboxId, unPinAlbum: "" });
    await super._fetch();

    if (callbackFn) {
      callbackFn();
    }
  }
}
