import ScanpixApi from "./index";

export default class UserImpersonationAction extends ScanpixApi {
  constructor() {
    super();
    super._setAction("login.action");
  }

  async login(username) {
    const formData = new FormData();
    formData.append("login", "");
    formData.set("loginUser.temporarySession", true);
    formData.set("loginUser.password", "blabla");
    formData.set("loginUser.username", username);
    formData.set("submit", "Logg inn");
    formData.append("ajaxCall", "true");

    return await super._post(formData);
  }
}
