//Action Types
export const SET_VIEW_MODE = "GALLERY_SET_VIEW_MODE";
export const SET_THUMBNAIL_SIZE = "GALLERY_SET_THUMBNAIL_SIZE";
export const INCREASE_THUMBNAIL_SIZE = "GALLERY_INCREASE_THUMBNAIL_SIZE";
export const DECREASE_THUMBNAIL_SIZE = "GALLERY_DECREASE_THUMBNAIL_SIZE";
export const SET_AUTO_UPDATE_SEARCH = "GALLERY_SET_AUTO_UPDATE_SEARCH";
export const SET_CAN_AUTO_UPDATE_SEARCH = "GALLERY_SET_CAN_AUTO_UPDATE_SEARCH";
export const SET_AUTO_UPDATE_SEARCH_PAUSED = "GALLERY_SET_AUTO_UPDATE_SEARCH_PAUSED";
export const SET_IS_ASSET_PREVIEW_OPEN = "GALLERY_SET_IS_ASSET_PREVIEW_OPEN";

//Action Creators
export const setViewMode = value => ({
  type: SET_VIEW_MODE,
  value
});

export const setThumbnailSize = value => ({
  type: SET_THUMBNAIL_SIZE,
  value
});

export const increaseThumbnailSize = () => ({
  type: INCREASE_THUMBNAIL_SIZE
});

export const decreaseThumbnailSize = () => ({
  type: DECREASE_THUMBNAIL_SIZE
});

export const setAutoUpdateSearch = value => ({
  type: SET_AUTO_UPDATE_SEARCH,
  value
});

export const setCanAutoUpdateSearch = value => ({
  type: SET_CAN_AUTO_UPDATE_SEARCH,
  value
});

export const setAutoUpdateSearchPaused = value => ({
  type: SET_AUTO_UPDATE_SEARCH_PAUSED,
  value
});

export const setIsAssetPreviewOpen = isOpen => ({ type: SET_IS_ASSET_PREVIEW_OPEN, isOpen });
