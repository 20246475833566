import {
  LS_KEY_PREFERRED_GALLERY_VIEW_MODE,
  LS_KEY_PREFERRED_GALLERY_THUMBNAIL_SIZE
} from "../../constants/localStorage";

import {
  SET_VIEW_MODE,
  SET_THUMBNAIL_SIZE,
  INCREASE_THUMBNAIL_SIZE,
  DECREASE_THUMBNAIL_SIZE,
  SET_AUTO_UPDATE_SEARCH,
  SET_CAN_AUTO_UPDATE_SEARCH,
  SET_AUTO_UPDATE_SEARCH_PAUSED,
  SET_IS_ASSET_PREVIEW_OPEN
} from "../actions/gallery";

const galleryReducer = (
  state = {
    viewMode: localStorage.getItem(LS_KEY_PREFERRED_GALLERY_VIEW_MODE) || "compact",
    thumbnailSize: Number(localStorage.getItem(LS_KEY_PREFERRED_GALLERY_THUMBNAIL_SIZE)) || 195,
    minThumbnailSize: 130,
    maxThumbnailSize: 400,
    autoUpdateSearch: false,
    canAutoUpdateSearch: false,
    autoUpdateSearchPaused: false,
    isAssetPreviewOpen: false
  },
  action
) => {
  switch (action.type) {
    case SET_VIEW_MODE: {
      localStorage.setItem(LS_KEY_PREFERRED_GALLERY_VIEW_MODE, action.value);
      return {
        ...state,
        viewMode: action.value
      };
    }

    case SET_THUMBNAIL_SIZE: {
      localStorage.setItem(LS_KEY_PREFERRED_GALLERY_THUMBNAIL_SIZE, action.value.toString());
      return {
        ...state,
        thumbnailSize: action.value
      };
    }

    case INCREASE_THUMBNAIL_SIZE: {
      let newThumbnailSize = state.thumbnailSize + 10;
      if (newThumbnailSize > state.maxThumbnailSize) {
        newThumbnailSize = state.maxThumbnailSize;
      }

      localStorage.setItem(LS_KEY_PREFERRED_GALLERY_THUMBNAIL_SIZE, newThumbnailSize.toString());

      return {
        ...state,
        thumbnailSize: newThumbnailSize
      };
    }

    case DECREASE_THUMBNAIL_SIZE: {
      let newThumbnailSize = state.thumbnailSize - 10;
      if (newThumbnailSize < state.minThumbnailSize) {
        newThumbnailSize = state.minThumbnailSize;
      }

      localStorage.setItem(LS_KEY_PREFERRED_GALLERY_THUMBNAIL_SIZE, newThumbnailSize.toString());

      return {
        ...state,
        thumbnailSize: newThumbnailSize
      };
    }

    case SET_AUTO_UPDATE_SEARCH: {
      return {
        ...state,
        autoUpdateSearch: action.value
      };
    }

    case SET_CAN_AUTO_UPDATE_SEARCH: {
      return {
        ...state,
        canAutoUpdateSearch: action.value
      };
    }

    case SET_AUTO_UPDATE_SEARCH_PAUSED: {
      return {
        ...state,
        autoUpdateSearchPaused: action.value
      };
    }

    case SET_IS_ASSET_PREVIEW_OPEN: {
      return {
        ...state,
        isAssetPreviewOpen: action.isOpen
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export default galleryReducer;
