import React, { useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import iframeEventBus from "../../helpers/iframe-event-bus";
import { setSynchronizeSplitScreenSearch } from "../../store/actions/split_screen";
import style from "../../assets/scss/components/search/iframe-synchronize-search.module.scss";
import { ReactComponent as SyncIcon } from "../../assets/icons/sync.svg";
import { ReactComponent as SyncDisabledIcon } from "../../assets/icons/sync-disabled.svg";

function IframeSynchronizeSearch({
  synchronizeSplitScreenSearch,
  setSynchronizeSplitScreenSearch,
  searchString
}) {
  const toggleSyncSearch = () => {
    const shouldBeSynchronized = !synchronizeSplitScreenSearch;

    setSynchronizeSplitScreenSearch(shouldBeSynchronized);
    iframeEventBus.emit("imagePortal:iframe:synchronizeSplitScreenSearch", shouldBeSynchronized);

    if (shouldBeSynchronized) {
      iframeEventBus.emit("imagePortal:iframe:searchStringChanged", searchString);
    }
  };

  // update setting from the Image Portal
  useEffect(() => {
    iframeEventBus.on("dam:iframe:synchronizeSplitScreenSearch", value => {
      setSynchronizeSplitScreenSearch(value);
    });
  }, [setSynchronizeSplitScreenSearch]);

  return (
    <div
      className={classNames(style["iframe-synchronize-search"], {
        [style["iframe-synchronize-search--enabled"]]: synchronizeSplitScreenSearch
      })}
      onClick={() => toggleSyncSearch()}
    >
      {synchronizeSplitScreenSearch ? (
        <SyncIcon className={style["iframe-synchronize-search__icon"]} />
      ) : (
        <SyncDisabledIcon className={style["iframe-synchronize-search__icon"]} />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  synchronizeSplitScreenSearch: state.splitScreen.synchronizeSearch,
  searchString: state.searchFilter.searchString
});

const mapDispatchToProps = { setSynchronizeSplitScreenSearch };

export default connect(mapStateToProps, mapDispatchToProps)(IframeSynchronizeSearch);
