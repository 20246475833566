import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { AnimateSharedLayout } from "framer-motion/dist/framer-motion";
import { retryLazy } from "./helpers/lazy-loade-chunk";

import store from "./store/root";

import "./assets/scss/global/base.scss";

import {
  indexRoute,
  previewRoute,
  albumIndexRoute,
  albumNameRoute,
  settingsRoute,
  needActivationRoute,
  registrationRoute
} from "./helpers/routes";
import PageTitle from "./components/helpers/page-title";
import GoogleAnalytics from "./components/system/google-analytics";
import Hotjar from "./components/system/hotjar";
import Theme from "./components/system/theme";
import Toast from "./components/system/toast";

import DefaultLayout from "./layouts/default";
import env from "./helpers/env";
import LoadingSpinner from "./components/loading-spinner";

// Lazy load page components
// Read more at https://reactjs.org/docs/code-splitting.html#reactlazy
const IndexPage = lazy(() => retryLazy(() => import("./pages/index")));
const AlbumIndexPage = lazy(() => retryLazy(() => import("./pages/album/index")));
const AlbumNamePage = lazy(() => retryLazy(() => import("./pages/album/_name")));
const SettingsPage = lazy(() => retryLazy(() => import("./pages/settings")));
const RegistrationPage = lazy(() => retryLazy(() => import("./pages/registration")));
const NeedActivation = lazy(() => retryLazy(() => import("./pages/need-activation")));
const NotFound = lazy(() => retryLazy(() => import("./pages/error/not-found")));

// Only load custom scrollbar on non-Mac systems
(async () => {
  if (navigator.platform.match("Mac") === null) {
    await import("./assets/scss/global/scrollbar.scss");
  }
})();

// Enable Sentry
if (env !== "development")
  Sentry.init({
    dsn: "https://3a2bc06d58c243b1b0e9b1fe062c1322@o409747.ingest.sentry.io/5591171",
    autoSessionTracking: true,
    release: process.env.REACT_APP_OPENSHIFT_BUILD_COMMIT_REF || null,
    environment: env,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [null]
      })
    ],
    normalizeDepth: 3,
    tracesSampleRate: 0.1
  });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <LoadingSpinner />
          </div>
        }
      >
        <Router>
          <PageTitle />
          <GoogleAnalytics />
          {env === "production" && <Hotjar />}
          <LastLocationProvider>
            <Theme>
              <DefaultLayout>
                <AnimateSharedLayout type="crossfade">
                  <Switch>
                    {/**
                     * Both of the previewRoute and indexRoute are rendering the same components.
                     * This prevents the search gallery to un-mounted from the dom and hence we can
                     * show the preview overlay smoothly
                     * */}
                    <Route exact path={[previewRoute, indexRoute]} component={IndexPage} />
                    <Route exact path={albumIndexRoute} component={AlbumIndexPage} />
                    <Route exact path={albumNameRoute} component={AlbumNamePage} />
                    <Route exact path={settingsRoute} component={SettingsPage} />
                    <Route exact path={registrationRoute} component={RegistrationPage} />
                    <Route exact path={needActivationRoute} component={NeedActivation} />
                    <Route component={NotFound} />
                  </Switch>
                </AnimateSharedLayout>
              </DefaultLayout>
            </Theme>
            <Toast />
          </LastLocationProvider>
        </Router>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
